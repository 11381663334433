
*,
::before,
::after {
  box-sizing: border-box; /* 1 */
  border-width: 0; /* 2 */
  border-style: solid; /* 2 */
  border-color: #e5e7eb; /* 2 */
}
th{
  width: 20%!important;
}
td{
  width: 10px!important;
  text-align: center!important;
  padding: 0;
}
td>svg{
  margin: auto;
  text-align: center;
}
::before,
::after {
  --tw-content: '';
}

/*
1. Use a consistent sensible line-height in all browsers.
2. Prevent adjustments of font size after orientation changes in iOS.
3. Use a more readable tab size.
4. Use the user's configured `sans` font-family by default.
5. Use the user's configured `sans` font-feature-settings by default.
6. Use the user's configured `sans` font-variation-settings by default.
*/

html {
  line-height: 1.5; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -moz-tab-size: 4; /* 3 */
  -o-tab-size: 4;
     tab-size: 4; /* 3 */
  font-family: Inter var, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; /* 4 */
  font-feature-settings: "cv02", "cv03", "cv04", "cv11"; /* 5 */
  font-variation-settings: normal; /* 6 */
}

/*
1. Remove the margin in all browsers.
2. Inherit line-height from `html` so users can set them as a class directly on the `html` element.
*/

body {
  margin: 0; /* 1 */
  line-height: inherit; /* 2 */
}

/*
1. Add the correct height in Firefox.
2. Correct the inheritance of border color in Firefox. (https://bugzilla.mozilla.org/show_bug.cgi?id=190655)
3. Ensure horizontal rules are visible by default.
*/

hr {
  height: 0; /* 1 */
  color: inherit; /* 2 */
  border-top-width: 1px; /* 3 */
}

/*
Add the correct text decoration in Chrome, Edge, and Safari.
*/

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
}

/*
Remove the default font size and weight for headings.
*/

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

/*
Reset links to optimize for opt-in styling instead of opt-out.
*/

a {
  color: inherit;
  text-decoration: inherit;
}

/*
Add the correct font weight in Edge and Safari.
*/

b,
strong {
  font-weight: bolder;
}

/*
1. Use the user's configured `mono` font family by default.
2. Correct the odd `em` font sizing in all browsers.
*/

code,
kbd,
samp,
pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/*
Add the correct font size in all browsers.
*/

small {
  font-size: 80%;
}

/*
Prevent `sub` and `sup` elements from affecting the line height in all browsers.
*/

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/*
1. Remove text indentation from table contents in Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=999088, https://bugs.webkit.org/show_bug.cgi?id=201297)
2. Correct table border color inheritance in all Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=935729, https://bugs.webkit.org/show_bug.cgi?id=195016)
3. Remove gaps between table borders by default.
*/

table {
  text-indent: 0; /* 1 */
  border-color: inherit; /* 2 */
  border-collapse: collapse; /* 3 */
}

/*
1. Change the font styles in all browsers.
2. Remove the margin in Firefox and Safari.
3. Remove default padding in all browsers.
*/

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  font-weight: inherit; /* 1 */
  line-height: inherit; /* 1 */
  color: inherit; /* 1 */
  margin: 0; /* 2 */
  padding: 0; /* 3 */
}

/*
Remove the inheritance of text transform in Edge and Firefox.
*/

button,
select {
  text-transform: none;
}

/*
1. Correct the inability to style clickable types in iOS and Safari.
2. Remove default button styles.
*/

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button; /* 1 */
  background-color: transparent; /* 2 */
  background-image: none; /* 2 */
}

/*
Use the modern Firefox focus style for all focusable elements.
*/

:-moz-focusring {
  outline: auto;
}

/*
Remove the additional `:invalid` styles in Firefox. (https://github.com/mozilla/gecko-dev/blob/2f9eacd9d3d995c937b4251a5557d95d494c9be1/layout/style/res/forms.css#L728-L737)
*/

:-moz-ui-invalid {
  box-shadow: none;
}

/*
Add the correct vertical alignment in Chrome and Firefox.
*/

progress {
  vertical-align: baseline;
}

/*
Correct the cursor style of increment and decrement buttons in Safari.
*/

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/*
1. Correct the odd appearance in Chrome and Safari.
2. Correct the outline style in Safari.
*/

[type='search'] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/*
Remove the inner padding in Chrome and Safari on macOS.
*/

::-webkit-search-decoration {
  -webkit-appearance: none;
}

/*
1. Correct the inability to style clickable types in iOS and Safari.
2. Change font properties to `inherit` in Safari.
*/

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/*
Add the correct display in Chrome and Safari.
*/

summary {
  display: list-item;
}

/*
Removes the default spacing and border for appropriate elements.
*/

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

/*
Prevent resizing textareas horizontally by default.
*/

textarea {
  resize: vertical;
}

/*
1. Reset the default placeholder opacity in Firefox. (https://github.com/tailwindlabs/tailwindcss/issues/3300)
2. Set the default placeholder color to the user's configured gray 400 color.
*/

input::-moz-placeholder, textarea::-moz-placeholder {
  opacity: 1; /* 1 */
  color: #9ca3af; /* 2 */
}

input::placeholder,
textarea::placeholder {
  opacity: 1; /* 1 */
  color: #9ca3af; /* 2 */
}

/*
Set the default cursor for buttons.
*/

button,
[role="button"] {
  cursor: pointer;
}

/*
Make sure disabled buttons don't get the pointer cursor.
*/
:disabled {
  cursor: default;
}

/*
1. Make replaced elements `display: block` by default. (https://github.com/mozdevs/cssremedy/issues/14)
2. Add `vertical-align: middle` to align replaced elements more sensibly by default. (https://github.com/jensimmons/cssremedy/issues/14#issuecomment-634934210)
   This can trigger a poorly considered lint error in some tools but is included by design.
*/

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block; /* 1 */
  vertical-align: middle; /* 2 */
}

/*
Constrain images and videos to the parent width and preserve their intrinsic aspect ratio. (https://github.com/mozdevs/cssremedy/issues/14)
*/

img,
video {
  max-width: 100%;
  height: auto;
}

/* Make elements with the HTML hidden attribute stay hidden by default */
[hidden] {
  display: none;
}

[type='text'],[type='email'],[type='url'],[type='password'],[type='number'],[type='date'],[type='datetime-local'],[type='month'],[type='search'],[type='tel'],[type='time'],[type='week'],[multiple],textarea,select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  border-radius: 0px;
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-shadow: 0 0 #0000;
}

[type='text']:focus, [type='email']:focus, [type='url']:focus, [type='password']:focus, [type='number']:focus, [type='date']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='week']:focus, [multiple]:focus, textarea:focus, select:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
}

input::-moz-placeholder, textarea::-moz-placeholder {
  color: #6b7280;
  opacity: 1;
}

input::placeholder,textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  min-height: 1.5em;
}

::-webkit-datetime-edit,::-webkit-datetime-edit-year-field,::-webkit-datetime-edit-month-field,::-webkit-datetime-edit-day-field,::-webkit-datetime-edit-hour-field,::-webkit-datetime-edit-minute-field,::-webkit-datetime-edit-second-field,::-webkit-datetime-edit-millisecond-field,::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
          color-adjust: exact;
}

[multiple] {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  padding-right: 0.75rem;
  -webkit-print-color-adjust: unset;
          color-adjust: unset;
}

[type='checkbox'],[type='radio'] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding: 0;
  -webkit-print-color-adjust: exact;
          color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  --tw-shadow: 0 0 #0000;
}

[type='checkbox'] {
  border-radius: 0px;
}

[type='radio'] {
  border-radius: 100%;
}

[type='checkbox']:focus,[type='radio']:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
}

[type='checkbox']:checked,[type='radio']:checked {
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

[type='checkbox']:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

[type='radio']:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

[type='checkbox']:checked:hover,[type='checkbox']:checked:focus,[type='radio']:checked:hover,[type='radio']:checked:focus {
  border-color: transparent;
  background-color: currentColor;
}

[type='checkbox']:indeterminate {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

[type='checkbox']:indeterminate:hover,[type='checkbox']:indeterminate:focus {
  border-color: transparent;
  background-color: currentColor;
}

[type='file'] {
  background: unset;
  border-color: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
  font-size: unset;
  line-height: inherit;
}

[type='file']:focus {
  outline: 1px auto -webkit-focus-ring-color;
}

/* @font-face {
  font-family: Inter var;
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url("/fonts/Inter-roman.var.woff2?v=3.18") format("woff2");
  font-named-instance: Regular;
}

@font-face {
  font-family: Inter var;
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url("/fonts/Inter-italic.var.woff2?v=3.18") format("woff2");
  font-named-instance: Italic;
} */

*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

html {
  font-feature-settings: 'cv03', 'cv04', 'cv11';
}

body {
  /* overflow: hidden; */
  --tw-bg-opacity: 1;
  /* background-color: rgb(229 231 235 / var(--tw-bg-opacity)); */
  font-family: Inter var, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-feature-settings: "cv02", "cv03", "cv04", "cv11";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.a {
  width: 100%;
}

@media (min-width: 640px) {

  .a {
    max-width: 640px;
  }
}

@media (min-width: 768px) {

  .a {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {

  .a {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {

  .a {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {

  .a {
    max-width: 1536px;
  }
}

.b {
  --tw-aspect-h: 1;
}

.c {
  --tw-aspect-h: 2;
}

.d {
  --tw-aspect-h: 3;
}

.e {
  --tw-aspect-h: 4;
}

.f {
  --tw-aspect-h: 5;
}

.g {
  --tw-aspect-h: 6;
}

.h {
  --tw-aspect-h: 7;
}

.i {
  position: relative;
  padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
  --tw-aspect-w: 1;
}

.i > * {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.j {
  position: relative;
  padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
  --tw-aspect-w: 10;
}

.j > * {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.k {
  position: relative;
  padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
  --tw-aspect-w: 12;
}

.k > * {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.l {
  position: relative;
  padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
  --tw-aspect-w: 2;
}

.l > * {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.m {
  position: relative;
  padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
  --tw-aspect-w: 3;
}

.m > * {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.n {
  position: relative;
  padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
  --tw-aspect-w: 4;
}

.n > * {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.o {
  position: relative;
  padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
  --tw-aspect-w: 5;
}

.o > * {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.p {
  color: var(--tw-prose-body);
  max-width: 65ch;
}

.p :where([class~="lead"]):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-lead);
  font-size: 1.25em;
  line-height: 1.6;
  margin-top: 1.2em;
  margin-bottom: 1.2em;
}

.p :where(a):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-links);
  text-decoration: underline;
  font-weight: 500;
}

.p :where(strong):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

.p :where(ol):not(:where([class~="not-prose"] *)) {
  list-style-type: decimal;
  padding-left: 1.625em;
}

.p :where(ol[type="A"]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.p :where(ol[type="a"]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.p :where(ol[type="A" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.p :where(ol[type="a" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.p :where(ol[type="I"]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.p :where(ol[type="i"]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.p :where(ol[type="I" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.p :where(ol[type="i" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.p :where(ol[type="1"]):not(:where([class~="not-prose"] *)) {
  list-style-type: decimal;
}

.p :where(ul):not(:where([class~="not-prose"] *)) {
  list-style-type: disc;
  padding-left: 1.625em;
}

.p :where(ol > li):not(:where([class~="not-prose"] *))::marker {
  font-weight: 400;
  color: var(--tw-prose-counters);
}

.p :where(ul > li):not(:where([class~="not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.p :where(hr):not(:where([class~="not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.p :where(blockquote):not(:where([class~="not-prose"] *)) {
  font-weight: 500;
  font-style: italic;
  color: var(--tw-prose-quotes);
  border-left-width: 0.25rem;
  border-left-color: var(--tw-prose-quote-borders);
  quotes: "\201C""\201D""\2018""\2019";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1em;
}

.p :where(blockquote p:first-of-type):not(:where([class~="not-prose"] *))::before {
  content: open-quote;
}

.p :where(blockquote p:last-of-type):not(:where([class~="not-prose"] *))::after {
  content: close-quote;
}

.p :where(h1):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 800;
  font-size: 2.25em;
  margin-top: 0;
  margin-bottom: 0.8888889em;
  line-height: 1.1111111;
}

.p :where(h1 strong):not(:where([class~="not-prose"] *)) {
  font-weight: 900;
}

.p :where(h2):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 700;
  font-size: 1.5em;
  margin-top: 2em;
  margin-bottom: 1em;
  line-height: 1.3333333;
}

.p :where(h2 strong):not(:where([class~="not-prose"] *)) {
  font-weight: 800;
}

.p :where(h3):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 600;
  font-size: 1.25em;
  margin-top: 1.6em;
  margin-bottom: 0.6em;
  line-height: 1.6;
}

.p :where(h3 strong):not(:where([class~="not-prose"] *)) {
  font-weight: 700;
}

.p :where(h4):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 600;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  line-height: 1.5;
}

.p :where(h4 strong):not(:where([class~="not-prose"] *)) {
  font-weight: 700;
}

.p :where(figure > *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.p :where(figcaption):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-captions);
  font-size: 0.875em;
  line-height: 1.4285714;
  margin-top: 0.8571429em;
}

.p :where(code):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-code);
  font-weight: 600;
  font-size: 0.875em;
}

.p :where(code):not(:where([class~="not-prose"] *))::before {
  content: "`";
}

.p :where(code):not(:where([class~="not-prose"] *))::after {
  content: "`";
}

.p :where(a code):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-links);
}

.p :where(pre):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  overflow-x: auto;
  font-weight: 400;
  font-size: 0.875em;
  line-height: 1.7142857;
  margin-top: 1.7142857em;
  margin-bottom: 1.7142857em;
  border-radius: 0.375rem;
  padding-top: 0.8571429em;
  padding-right: 1.1428571em;
  padding-bottom: 0.8571429em;
  padding-left: 1.1428571em;
}

.p :where(pre code):not(:where([class~="not-prose"] *)) {
  background-color: transparent;
  border-width: 0;
  border-radius: 0;
  padding: 0;
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}

.p :where(pre code):not(:where([class~="not-prose"] *))::before {
  content: none;
}

.p :where(pre code):not(:where([class~="not-prose"] *))::after {
  content: none;
}

.p :where(table):not(:where([class~="not-prose"] *)) {
  width: 100%;
  table-layout: auto;
  text-align: left;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: 0.875em;
  line-height: 1.7142857;
}

.p :where(thead):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.p :where(thead th):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 600;
  vertical-align: bottom;
  padding-right: 0.5714286em;
  padding-bottom: 0.5714286em;
  padding-left: 0.5714286em;
}

.p :where(tbody tr):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.p :where(tbody tr:last-child):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 0;
}

.p :where(tbody td):not(:where([class~="not-prose"] *)) {
  vertical-align: baseline;
  padding-top: 0.5714286em;
  padding-right: 0.5714286em;
  padding-bottom: 0.5714286em;
  padding-left: 0.5714286em;
}

.p {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: rgb(0 0 0 / 50%);
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

.p :where(p):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.p :where(img):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.p :where(video):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.p :where(figure):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.p :where(h2 code):not(:where([class~="not-prose"] *)) {
  font-size: 0.875em;
}

.p :where(h3 code):not(:where([class~="not-prose"] *)) {
  font-size: 0.9em;
}

.p :where(li):not(:where([class~="not-prose"] *)) {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.p :where(ol > li):not(:where([class~="not-prose"] *)) {
  padding-left: 0.375em;
}

.p :where(ul > li):not(:where([class~="not-prose"] *)) {
  padding-left: 0.375em;
}

.p > :where(ul > li p):not(:where([class~="not-prose"] *)) {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}

.p > :where(ul > li > *:first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.p > :where(ul > li > *:last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.p > :where(ol > li > *:first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.p > :where(ol > li > *:last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.p :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"] *)) {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}

.p :where(hr + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.p :where(h2 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.p :where(h3 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.p :where(h4 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.p :where(thead th:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}

.p :where(thead th:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}

.p :where(tbody td:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}

.p :where(tbody td:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}

.p > :where(:first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.p > :where(:last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0;
}

.q {
  font-size: 0.875rem;
  line-height: 1.7142857;
}

.q :where(p):not(:where([class~="not-prose"] *)) {
  margin-top: 1.1428571em;
  margin-bottom: 1.1428571em;
}

.q :where([class~="lead"]):not(:where([class~="not-prose"] *)) {
  font-size: 1.2857143em;
  line-height: 1.5555556;
  margin-top: 0.8888889em;
  margin-bottom: 0.8888889em;
}

.q :where(blockquote):not(:where([class~="not-prose"] *)) {
  margin-top: 1.3333333em;
  margin-bottom: 1.3333333em;
  padding-left: 1.1111111em;
}

.q :where(h1):not(:where([class~="not-prose"] *)) {
  font-size: 2.1428571em;
  margin-top: 0;
  margin-bottom: 0.8em;
  line-height: 1.2;
}

.q :where(h2):not(:where([class~="not-prose"] *)) {
  font-size: 1.4285714em;
  margin-top: 1.6em;
  margin-bottom: 0.8em;
  line-height: 1.4;
}

.q :where(h3):not(:where([class~="not-prose"] *)) {
  font-size: 1.2857143em;
  margin-top: 1.5555556em;
  margin-bottom: 0.4444444em;
  line-height: 1.5555556;
}

.q :where(h4):not(:where([class~="not-prose"] *)) {
  margin-top: 1.4285714em;
  margin-bottom: 0.5714286em;
  line-height: 1.4285714;
}

.q :where(img):not(:where([class~="not-prose"] *)) {
  margin-top: 1.7142857em;
  margin-bottom: 1.7142857em;
}

.q :where(video):not(:where([class~="not-prose"] *)) {
  margin-top: 1.7142857em;
  margin-bottom: 1.7142857em;
}

.q :where(figure):not(:where([class~="not-prose"] *)) {
  margin-top: 1.7142857em;
  margin-bottom: 1.7142857em;
}

.q :where(figure > *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.q :where(figcaption):not(:where([class~="not-prose"] *)) {
  font-size: 0.8571429em;
  line-height: 1.3333333;
  margin-top: 0.6666667em;
}

.q :where(code):not(:where([class~="not-prose"] *)) {
  font-size: 0.8571429em;
}

.q :where(h2 code):not(:where([class~="not-prose"] *)) {
  font-size: 0.9em;
}

.q :where(h3 code):not(:where([class~="not-prose"] *)) {
  font-size: 0.8888889em;
}

.q :where(pre):not(:where([class~="not-prose"] *)) {
  font-size: 0.8571429em;
  line-height: 1.6666667;
  margin-top: 1.6666667em;
  margin-bottom: 1.6666667em;
  border-radius: 0.25rem;
  padding-top: 0.6666667em;
  padding-right: 1em;
  padding-bottom: 0.6666667em;
  padding-left: 1em;
}

.q :where(ol):not(:where([class~="not-prose"] *)) {
  padding-left: 1.5714286em;
}

.q :where(ul):not(:where([class~="not-prose"] *)) {
  padding-left: 1.5714286em;
}

.q :where(li):not(:where([class~="not-prose"] *)) {
  margin-top: 0.2857143em;
  margin-bottom: 0.2857143em;
}

.q :where(ol > li):not(:where([class~="not-prose"] *)) {
  padding-left: 0.4285714em;
}

.q :where(ul > li):not(:where([class~="not-prose"] *)) {
  padding-left: 0.4285714em;
}

.q > :where(ul > li p):not(:where([class~="not-prose"] *)) {
  margin-top: 0.5714286em;
  margin-bottom: 0.5714286em;
}

.q > :where(ul > li > *:first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.1428571em;
}

.q > :where(ul > li > *:last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.1428571em;
}

.q > :where(ol > li > *:first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.1428571em;
}

.q > :where(ol > li > *:last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.1428571em;
}

.q :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"] *)) {
  margin-top: 0.5714286em;
  margin-bottom: 0.5714286em;
}

.q :where(hr):not(:where([class~="not-prose"] *)) {
  margin-top: 2.8571429em;
  margin-bottom: 2.8571429em;
}

.q :where(hr + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.q :where(h2 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.q :where(h3 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.q :where(h4 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.q :where(table):not(:where([class~="not-prose"] *)) {
  font-size: 0.8571429em;
  line-height: 1.5;
}

.q :where(thead th):not(:where([class~="not-prose"] *)) {
  padding-right: 1em;
  padding-bottom: 0.6666667em;
  padding-left: 1em;
}

.q :where(thead th:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}

.q :where(thead th:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}

.q :where(tbody td):not(:where([class~="not-prose"] *)) {
  padding-top: 0.6666667em;
  padding-right: 1em;
  padding-bottom: 0.6666667em;
  padding-left: 1em;
}

.q :where(tbody td:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}

.q :where(tbody td:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}

.q > :where(:first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.q > :where(:last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0;
}

.r {
  font-size: 1.125rem;
  line-height: 1.7777778;
}

.r :where(p):not(:where([class~="not-prose"] *)) {
  margin-top: 1.3333333em;
  margin-bottom: 1.3333333em;
}

.r :where([class~="lead"]):not(:where([class~="not-prose"] *)) {
  font-size: 1.2222222em;
  line-height: 1.4545455;
  margin-top: 1.0909091em;
  margin-bottom: 1.0909091em;
}

.r :where(blockquote):not(:where([class~="not-prose"] *)) {
  margin-top: 1.6666667em;
  margin-bottom: 1.6666667em;
  padding-left: 1em;
}

.r :where(h1):not(:where([class~="not-prose"] *)) {
  font-size: 2.6666667em;
  margin-top: 0;
  margin-bottom: 0.8333333em;
  line-height: 1;
}

.r :where(h2):not(:where([class~="not-prose"] *)) {
  font-size: 1.6666667em;
  margin-top: 1.8666667em;
  margin-bottom: 1.0666667em;
  line-height: 1.3333333;
}

.r :where(h3):not(:where([class~="not-prose"] *)) {
  font-size: 1.3333333em;
  margin-top: 1.6666667em;
  margin-bottom: 0.6666667em;
  line-height: 1.5;
}

.r :where(h4):not(:where([class~="not-prose"] *)) {
  margin-top: 1.7777778em;
  margin-bottom: 0.4444444em;
  line-height: 1.5555556;
}

.r :where(img):not(:where([class~="not-prose"] *)) {
  margin-top: 1.7777778em;
  margin-bottom: 1.7777778em;
}

.r :where(video):not(:where([class~="not-prose"] *)) {
  margin-top: 1.7777778em;
  margin-bottom: 1.7777778em;
}

.r :where(figure):not(:where([class~="not-prose"] *)) {
  margin-top: 1.7777778em;
  margin-bottom: 1.7777778em;
}

.r :where(figure > *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.r :where(figcaption):not(:where([class~="not-prose"] *)) {
  font-size: 0.8888889em;
  line-height: 1.5;
  margin-top: 1em;
}

.r :where(code):not(:where([class~="not-prose"] *)) {
  font-size: 0.8888889em;
}

.r :where(h2 code):not(:where([class~="not-prose"] *)) {
  font-size: 0.8666667em;
}

.r :where(h3 code):not(:where([class~="not-prose"] *)) {
  font-size: 0.875em;
}

.r :where(pre):not(:where([class~="not-prose"] *)) {
  font-size: 0.8888889em;
  line-height: 1.75;
  margin-top: 2em;
  margin-bottom: 2em;
  border-radius: 0.375rem;
  padding-top: 1em;
  padding-right: 1.5em;
  padding-bottom: 1em;
  padding-left: 1.5em;
}

.r :where(ol):not(:where([class~="not-prose"] *)) {
  padding-left: 1.5555556em;
}

.r :where(ul):not(:where([class~="not-prose"] *)) {
  padding-left: 1.5555556em;
}

.r :where(li):not(:where([class~="not-prose"] *)) {
  margin-top: 0.6666667em;
  margin-bottom: 0.6666667em;
}

.r :where(ol > li):not(:where([class~="not-prose"] *)) {
  padding-left: 0.4444444em;
}

.r :where(ul > li):not(:where([class~="not-prose"] *)) {
  padding-left: 0.4444444em;
}

.r > :where(ul > li p):not(:where([class~="not-prose"] *)) {
  margin-top: 0.8888889em;
  margin-bottom: 0.8888889em;
}

.r > :where(ul > li > *:first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.3333333em;
}

.r > :where(ul > li > *:last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.3333333em;
}

.r > :where(ol > li > *:first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.3333333em;
}

.r > :where(ol > li > *:last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.3333333em;
}

.r :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"] *)) {
  margin-top: 0.8888889em;
  margin-bottom: 0.8888889em;
}

.r :where(hr):not(:where([class~="not-prose"] *)) {
  margin-top: 3.1111111em;
  margin-bottom: 3.1111111em;
}

.r :where(hr + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.r :where(h2 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.r :where(h3 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.r :where(h4 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.r :where(table):not(:where([class~="not-prose"] *)) {
  font-size: 0.8888889em;
  line-height: 1.5;
}

.r :where(thead th):not(:where([class~="not-prose"] *)) {
  padding-right: 0.75em;
  padding-bottom: 0.75em;
  padding-left: 0.75em;
}

.r :where(thead th:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}

.r :where(thead th:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}

.r :where(tbody td):not(:where([class~="not-prose"] *)) {
  padding-top: 0.75em;
  padding-right: 0.75em;
  padding-bottom: 0.75em;
  padding-left: 0.75em;
}

.r :where(tbody td:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}

.r :where(tbody td:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}

.r > :where(:first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.r > :where(:last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0;
}

.s {
  --tw-prose-links: #4f46e5;
  --tw-prose-invert-links: #6366f1;
}
.t {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.u {
  pointer-events: none;
}
.v {
  pointer-events: auto;
}
.w {
  visibility: visible;
}
.x {
  visibility: hidden;
}
.y {
  position: static;
}
.z {
  position: fixed;
}
.aa {
  position: absolute;
}
.ab {
  position: relative;
}
.ac {
  position: sticky;
}
.ad {
  inset: -0px;
}
.ae {
  inset: -0.125rem;
}
.af {
  inset: -0.25rem;
}
.ag {
  inset: -0.375rem;
}
.ah {
  inset: -0.5rem;
}
.ai {
  inset: -0.625rem;
}
.aj {
  inset: -1px;
}
.ak {
  inset: 0px;
}
.al {
  inset: 0.25rem;
}
.am {
  left: -5rem;
  right: -5rem;
}
.an {
  top: -1px;
  bottom: -1px;
}
.ao {
  left: 0px;
  right: 0px;
}
.ap {
  left: 1rem;
  right: 1rem;
}
.aq {
  left: 2rem;
  right: 2rem;
}
.ar {
  left: 1px;
  right: 1px;
}
.as {
  top: 0px;
  bottom: 0px;
}
.at {
  bottom: -0px;
}
.au {
  bottom: -0.125rem;
}
.av {
  bottom: -6rem;
}
.aw {
  bottom: -12rem;
}
.ax {
  bottom: -1.5rem;
}
.ay {
  bottom: -2rem;
}
.az {
  bottom: -1px;
}
.ba {
  left: -0.25rem;
}
.bb {
  left: -0.375rem;
}
.bc {
  left: -0.5rem;
}
.bd {
  left: -0.75rem;
}
.be {
  left: -10rem;
}
.bf {
  left: -14rem;
}
.bg {
  left: -2rem;
}
.bh {
  left: -20rem;
}
.bi {
  left: -24rem;
}
.bj {
  right: -0.25rem;
}
.bk {
  right: -0.375rem;
}
.bl {
  right: -3.5rem;
}
.bm {
  right: -0.5rem;
}
.bn {
  top: -0.25rem;
}
.bo {
  top: -2.5rem;
}
.bp {
  top: -3rem;
}
.bq {
  top: -4rem;
}
.br {
  top: -0.5rem;
}
.bs {
  top: -6rem;
}
.bt {
  top: -0.75rem;
}
.bu {
  top: -8rem;
}
.bv {
  top: -1rem;
}
.bw {
  top: -10rem;
}
.bx {
  top: -13rem;
}
.by {
  top: -14rem;
}
.bz {
  top: -20rem;
}
.ca {
  top: -40rem;
}
.cb {
  top: -1px;
}
.cc {
  bottom: 0px;
}
.cd {
  bottom: 2.5rem;
}
.ce {
  bottom: 1.5rem;
}
.cf {
  bottom: 100%;
}
.cg {
  left: 0px;
}
.ch {
  left: 0.25rem;
}
.ci {
  left: 50%;
}
.cj {
  left: 3rem;
}
.ck {
  left: 3.5rem;
}
.cl {
  left: 4rem;
}
.cm {
  left: 0.5rem;
}
.cn {
  left: 5rem;
}
.co {
  left: 6rem;
}
.cp {
  left: 75%;
}
.cq {
  left: 1rem;
}
.cr {
  left: 1.25rem;
}
.cs {
  left: 1.5rem;
}
.ct {
  left: 18rem;
}
.cu {
  left: 2rem;
}
.cv {
  left: calc(50% + 3rem);
}
.cw {
  left: calc(50% - 11rem);
}
.cx {
  left: calc(50% - 19rem);
}
.cy {
  left: calc(50% - 4rem);
}
.cz {
  left: max(-7rem,calc(50% - 52rem));
}
.da {
  left: max(45rem,calc(50% + 8rem));
}
.db {
  left: max(50%,25rem);
}
.dc {
  left: max(6rem,33%);
}
.dd {
  left: 100%;
}
.de {
  right: 0px;
}
.df {
  right: 50%;
}
.dg {
  right: 2.5rem;
}
.dh {
  right: 0.75rem;
}
.di {
  right: 1rem;
}
.dj {
  right: 1.5rem;
}
.dk {
  right: 2rem;
}
.dl {
  right: 2.25rem;
}
.dm {
  right: 100%;
}
.dn {
  top: 0px;
}
.do {
  top: 0.25rem;
}
.dp {
  top: 50%;
}
.dq {
  top: 2.5rem;
}
.dr {
  top: 4rem;
}
.ds {
  top: 0.75rem;
}
.dt {
  top: 0.875rem;
}
.du {
  top: 1rem;
}
.dv {
  top: 1.25rem;
}
.dw {
  top: 1.5rem;
}
.dx {
  top: 2rem;
}
.dy {
  top: -10rem;
}
.dz {
  top: calc(100% - 13rem);
}
.ea {
  top: calc(50% - 36rem);
}
.eb {
  top: 100%;
}
.ec {
  isolation: isolate;
}
.ed {
  z-index: -10;
}
.ee {
  z-index: -20;
}
.ef {
  z-index: 0;
}
.eg {
  z-index: 10;
}
.eh {
  z-index: 20;
}
.ei {
  z-index: 30;
}
.ej {
  z-index: 40;
}
.ek {
  z-index: 50;
}
.el {
  order: 1;
}
.em {
  order: 2;
}
.en {
  order: 3;
}
.eo {
  order: -9999;
}
.ep {
  order: 9999;
}
.eq {
  grid-column: span 1 / span 1;
}
.er {
  grid-column: span 12 / span 12;
}
.es {
  grid-column: span 2 / span 2;
}
.et {
  grid-column: span 3 / span 3;
}
.eu {
  grid-column: span 4 / span 4;
}
.ev {
  grid-column: span 6 / span 6;
}
.ew {
  grid-column: span 8 / span 8;
}
.ex {
  grid-column: 1 / -1;
}
.ey {
  grid-column-start: 1;
}
.ez {
  grid-column-start: 2;
}
.fa {
  grid-column-start: 3;
}
.fb {
  grid-column-start: 4;
}
.fc {
  grid-column-start: 5;
}
.fd {
  grid-column-start: 6;
}
.fe {
  grid-column-start: 7;
}
.ff {
  grid-column-start: 8;
}
.fg {
  grid-column-end: 1;
}
.fh {
  grid-column-end: 2;
}
.fi {
  grid-column-end: 3;
}
.fj {
  grid-row: span 2 / span 2;
}
.fk {
  grid-row: 1 / -1;
}
.fl {
  grid-row-start: 1;
}
.fm {
  grid-row-end: 1;
}
.fn {
  grid-row-end: 2;
}
.fo {
  margin: -0px;
}
.fp {
  margin: -0.125rem;
}
.fq {
  margin: -0.25rem;
}
.fr {
  margin: -0.375rem;
}
.fs {
  margin: -0.5rem;
}
.ft {
  margin: -0.625rem;
}
.fu {
  margin: -0.75rem;
}
.fv {
  margin: 0.25rem;
}
.fw {
  margin-left: -0px;
  margin-right: -0px;
}
.fx {
  margin-left: -0.125rem;
  margin-right: -0.125rem;
}
.fy {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}
.fz {
  margin-left: -0.375rem;
  margin-right: -0.375rem;
}
.ga {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.gb {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}
.gc {
  margin-left: -1rem;
  margin-right: -1rem;
}
.gd {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}
.ge {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}
.gf {
  margin-left: -2rem;
  margin-right: -2rem;
}
.gg {
  margin-left: -1px;
  margin-right: -1px;
}
.gh {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}
.gi {
  margin-top: -0.375rem;
  margin-bottom: -0.375rem;
}
.gj {
  margin-top: -2.5rem;
  margin-bottom: -2.5rem;
}
.gk {
  margin-top: -3rem;
  margin-bottom: -3rem;
}
.gl {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}
.gm {
  margin-top: -0.75rem;
  margin-bottom: -0.75rem;
}
.gn {
  margin-top: -1rem;
  margin-bottom: -1rem;
}
.go {
  margin-top: -1.25rem;
  margin-bottom: -1.25rem;
}
.gp {
  margin-top: -1.5rem;
  margin-bottom: -1.5rem;
}
.gq {
  margin-top: -2rem;
  margin-bottom: -2rem;
}
.gr {
  margin-left: 0px;
  margin-right: 0px;
}
.gs {
  margin-left: 0.125rem;
  margin-right: 0.125rem;
}
.gt {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.gu {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.gv {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
.gw {
  margin-left: 1rem;
  margin-right: 1rem;
}
.gx {
  margin-left: auto;
  margin-right: auto;
}
.gy {
  margin-left: 1px;
  margin-right: 1px;
}
.gz {
  margin-top: 6rem;
  margin-bottom: 6rem;
}
.ha {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.hb {
  margin-bottom: -0.25rem;
}
.hc {
  margin-bottom: -2.5rem;
}
.hd {
  margin-bottom: -3rem;
}
.he {
  margin-bottom: -4rem;
}
.hf {
  margin-bottom: -0.5rem;
}
.hg {
  margin-bottom: -5rem;
}
.hh {
  margin-bottom: -12rem;
}
.hi {
  margin-bottom: -1.5rem;
}
.hj {
  margin-bottom: -2rem;
}
.hk {
  margin-bottom: -1px;
}
.hl {
  margin-left: -0px;
}
.hm {
  margin-left: -0.125rem;
}
.hn {
  margin-left: -0.25rem;
}
.ho {
  margin-left: -0.375rem;
}
.hp {
  margin-left: -3rem;
}
.hq {
  margin-left: -3.5rem;
}
.hr {
  margin-left: -4rem;
}
.hs {
  margin-left: -0.5rem;
}
.ht {
  margin-left: -5rem;
}
.hu {
  margin-left: -6rem;
}
.hv {
  margin-left: -0.75rem;
}
.hw {
  margin-left: -1rem;
}
.hx {
  margin-left: -12rem;
}
.hy {
  margin-left: -1.5rem;
}
.hz {
  margin-left: -2rem;
}
.ia {
  margin-left: -1px;
}
.ib {
  margin-right: -0px;
}
.ic {
  margin-right: -0.125rem;
}
.id {
  margin-right: -0.25rem;
}
.ie {
  margin-right: -0.375rem;
}
.if {
  margin-right: -2.5rem;
}
.ig {
  margin-right: -3rem;
}
.ih {
  margin-right: -3.5rem;
}
.ii {
  margin-right: -0.5rem;
}
.ij {
  margin-right: -5rem;
}
.ik {
  margin-right: -0.75rem;
}
.il {
  margin-right: -10rem;
}
.im {
  margin-right: -12rem;
}
.in {
  margin-right: -24rem;
}
.io {
  margin-right: -1px;
}
.ip {
  margin-top: -0px;
}
.iq {
  margin-top: -0.125rem;
}
.ir {
  margin-top: -0.25rem;
}
.is {
  margin-top: -3rem;
}
.it {
  margin-top: -4rem;
}
.iu {
  margin-top: -0.5rem;
}
.iv {
  margin-top: -0.625rem;
}
.iw {
  margin-top: -5rem;
}
.ix {
  margin-top: -6rem;
}
.iy {
  margin-top: -8rem;
}
.iz {
  margin-top: -1rem;
}
.ja {
  margin-top: -1.5rem;
}
.jb {
  margin-top: -18rem;
}
.jc {
  margin-top: -2rem;
}
.jd {
  margin-top: -20rem;
}
.je {
  margin-top: -24rem;
}
.jf {
  margin-top: -1px;
}
.jg {
  margin-bottom: 0.25rem;
}
.jh {
  margin-bottom: 0.5rem;
}
.ji {
  margin-bottom: 1rem;
}
.jj {
  margin-bottom: 12rem;
}
.jk {
  margin-bottom: 1.5rem;
}
.jl {
  margin-bottom: 2rem;
}
.jm {
  margin-bottom: -12%;
}
.jn {
  margin-left: 0px;
}
.jo {
  margin-left: 0.125rem;
}
.jp {
  margin-left: 0.25rem;
}
.jq {
  margin-left: 0.375rem;
}
.jr {
  margin-left: 2.5rem;
}
.js {
  margin-left: 4rem;
}
.jt {
  margin-left: 0.5rem;
}
.ju {
  margin-left: 0.625rem;
}
.jv {
  margin-left: 6rem;
}
.jw {
  margin-left: 0.75rem;
}
.jx {
  margin-left: 1rem;
}
.jy {
  margin-left: 1.25rem;
}
.jz {
  margin-left: 1.5rem;
}
.ka {
  margin-left: 2rem;
}
.kb {
  margin-left: 2.25rem;
}
.kc {
  margin-left: -22rem;
}
.kd {
  margin-left: max(50%,38rem);
}
.ke {
  margin-left: auto;
}
.kf {
  margin-left: 1px;
}
.kg {
  margin-right: 0px;
}
.kh {
  margin-right: 0.125rem;
}
.ki {
  margin-right: 0.25rem;
}
.kj {
  margin-right: 0.375rem;
}
.kk {
  margin-right: 3rem;
}
.kl {
  margin-right: 4rem;
}
.km {
  margin-right: 0.5rem;
}
.kn {
  margin-right: 0.75rem;
}
.ko {
  margin-right: 1rem;
}
.kp {
  margin-right: 1.5rem;
}
.kq {
  margin-right: 2rem;
}
.kr {
  margin-right: auto;
}
.ks {
  margin-top: 0px;
}
.kt {
  margin-top: 0.125rem;
}
.ku {
  margin-top: 0.25rem;
}
.kv {
  margin-top: 0.375rem;
}
.kw {
  margin-top: 2.5rem;
}
.kx {
  margin-top: 2.75rem;
}
.ky {
  margin-top: 3rem;
}
.kz {
  margin-top: 3.5rem;
}
.la {
  margin-top: 4rem;
}
.lb {
  margin-top: 0.5rem;
}
.lc {
  margin-top: 0.625rem;
}
.ld {
  margin-top: 5rem;
}
.le {
  margin-top: 6rem;
}
.lf {
  margin-top: 0.75rem;
}
.lg {
  margin-top: 8rem;
}
.lh {
  margin-top: 1rem;
}
.li {
  margin-top: 10rem;
}
.lj {
  margin-top: 1.25rem;
}
.lk {
  margin-top: 1.5rem;
}
.ll {
  margin-top: 1.75rem;
}
.lm {
  margin-top: 2rem;
}
.ln {
  margin-top: 2.25rem;
}
.lo {
  margin-top: 24rem;
}
.lp {
  margin-top: auto;
}
.lq {
  margin-top: 1px;
}
.lr {
  box-sizing: content-box;
}
.ls {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.lt {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.lu {
  display: block;
}
.lv {
  display: inline-block;
}
.lw {
  display: inline;
}
.lx {
  display: flex;
}
.ly {
  display: inline-flex;
}
.lz {
  display: table;
}
.ma {
  display: flow-root;
}
.mb {
  display: grid;
}
.mc {
  display: contents;
}
.md {
  display: none;
}
.me {
  aspect-ratio: 1097/1023;
}
.mf {
  aspect-ratio: 1097/845;
}
.mg {
  aspect-ratio: 1108/632;
}
.mh {
  aspect-ratio: 1154/678;
}
.mi {
  aspect-ratio: 1155/678;
}
.mj {
  aspect-ratio: 1266/975;
}
.mk {
  aspect-ratio: 1313/771;
}
.ml {
  aspect-ratio: 1318/752;
}
.mm {
  aspect-ratio: 14/13;
}
.mn {
  aspect-ratio: 1404/767;
}
.mo {
  aspect-ratio: 16/9;
}
.mp {
  aspect-ratio: 2/1;
}
.mq {
  aspect-ratio: 2/3;
}
.mr {
  aspect-ratio: 3/2;
}
.ms {
  aspect-ratio: 4/3;
}
.mt {
  aspect-ratio: 4/5;
}
.mu {
  aspect-ratio: 5/2;
}
.mv {
  aspect-ratio: 577/310;
}
.mw {
  aspect-ratio: 6/5;
}
.mx {
  aspect-ratio: 7/5;
}
.my {
  aspect-ratio: 801/1036;
}
.mz {
  aspect-ratio: 9/4;
}
.na {
  aspect-ratio: 16 / 9;
}
.nb {
  height: 0px;
}
.nc {
  height: 0.125rem;
}
.nd {
  height: 0.25rem;
}
.ne {
  height: 0.375rem;
}
.nf {
  height: 50%;
}
.ng {
  height: 33.333333%;
}
.nh {
  height: 2.5rem;
}
.ni {
  height: 2.75rem;
}
.nj {
  height: 3rem;
}
.nk {
  height: 3.5rem;
}
.nl {
  height: 4rem;
}
.nm {
  height: 0.5rem;
}
.nn {
  height: 0.625rem;
}
.no {
  height: 66.666667%;
}
.np {
  height: 5rem;
}
.nq {
  height: 6rem;
}
.nr {
  height: 0.75rem;
}
.ns {
  height: 0.875rem;
}
.nt {
  height: 75%;
}
.nu {
  height: 8rem;
}
.nv {
  height: 9rem;
}
.nw {
  height: 1rem;
}
.nx {
  height: 10rem;
}
.ny {
  height: 12rem;
}
.nz {
  height: 1.25rem;
}
.oa {
  height: 83.333333%;
}
.ob {
  height: 14rem;
}
.oc {
  height: 1.5rem;
}
.od {
  height: 16rem;
}
.oe {
  height: 1.75rem;
}
.of {
  height: 18rem;
}
.og {
  height: 2rem;
}
.oh {
  height: 20rem;
}
.oi {
  height: 2.25rem;
}
.oj {
  height: 24rem;
}
.ok {
  height: 1008px;
}
.ol {
  height: 1024px;
}
.om {
  height: 1078px;
}
.on {
  height: 1152px;
}
.oo {
  height: 1923px;
}
.op {
  height: 192px;
}
.oq {
  height: 256px;
}
.or {
  height: 367px;
}
.os {
  height: 40rem;
}
.ot {
  height: 415px;
}
.ou {
  height: 485px;
}
.ov {
  height: 495px;
}
.ow {
  height: 50rem;
}
.ox {
  height: 576px;
}
.oy {
  height: 640px;
}
.oz {
  height: 64rem;
}
.pa {
  height: 672px;
}
.pb {
  height: 720px;
}
.pc {
  height: 768px;
}
.pd {
  height: 802px;
}
.pe {
  height: 900px;
}
.pf {
  height: 940px;
}
.pg {
  height: auto;
}
.ph {
  height: 100%;
}
.pi {
  height: 1px;
}
.pj {
  max-height: 3rem;
}
.pk {
  max-height: 14rem;
}
.pl {
  max-height: 15rem;
}
.pm {
  max-height: 18rem;
}
.pn {
  max-height: 20rem;
}
.po {
  max-height: 24rem;
}
.pp {
  max-height: 600px;
}
.pq {
  max-height: 800px;
}
.pr {
  min-height: 0px;
}
.ps {
  min-height: 1007px;
}
.pt {
  min-height: 1024px;
}
.pu {
  min-height: 1100px;
}
.pv {
  min-height: 1117px;
}
.pw {
  min-height: 192px;
}
.px {
  min-height: 448px;
}
.py {
  min-height: 464px;
}
.pz {
  min-height: 480px;
}
.qa {
  min-height: 500px;
}
.qb {
  min-height: 564px;
}
.qc {
  min-height: 583px;
}
.qd {
  min-height: 592px;
}
.qe {
  min-height: 600px;
}
.qf {
  min-height: 640px;
}
.qg {
  min-height: 64rem;
}
.qh {
  min-height: 660px;
}
.qi {
  min-height: 678px;
}
.qj {
  min-height: 700px;
}
.qk {
  min-height: 712px;
}
.ql {
  min-height: 720px;
}
.qm {
  min-height: 730px;
}
.qn {
  min-height: 768px;
}
.qo {
  min-height: 772px;
}
.qp {
  min-height: 800px;
}
.qq {
  min-height: 830px;
}
.qr {
  min-height: 840px;
}
.qs {
  min-height: 850px;
}
.qt {
  min-height: 851px;
}
.qu {
  min-height: 867px;
}
.qv {
  min-height: 873px;
}
.qw {
  min-height: 896px;
}
.qx {
  min-height: 900px;
}
.qy {
  min-height: 960px;
}
.qz {
  min-height: 979px;
}
.ra {
  min-height: 980px;
}
.rb {
  min-height: 100%;
}
.rc {
  width: 0px;
}
.rd {
  width: 0.125rem;
}
.re {
  width: 0.25rem;
}
.rf {
  width: 0.375rem;
}
.rg {
  width: 50%;
}
.rh {
  width: 25%;
}
.ri {
  width: 20%;
}
.rj {
  width: 2.5rem;
}
.rk {
  width: 2.75rem;
}
.rl {
  width: 3rem;
}
.rm {
  width: 3.5rem;
}
.rn {
  width: 4rem;
}
.ro {
  width: 0.5rem;
}
.rp {
  width: 0.625rem;
}
.rq {
  width: 5rem;
}
.rr {
  width: 6rem;
}
.rs {
  width: 7rem;
}
.rt {
  width: 0.75rem;
}
.ru {
  width: 0.875rem;
}
.rv {
  width: 8rem;
}
.rw {
  width: 9rem;
}
.rx {
  width: 1rem;
}
.ry {
  width: 10rem;
}
.rz {
  width: 11rem;
}
.sa {
  width: 12rem;
}
.sb {
  width: 1.25rem;
}
.sc {
  width: 13rem;
}
.sd {
  width: 14rem;
}
.se {
  width: 1.5rem;
}
.sf {
  width: 15rem;
}
.sg {
  width: 16rem;
}
.sh {
  width: 1.75rem;
}
.si {
  width: 18rem;
}
.sj {
  width: 2rem;
}
.sk {
  width: 20rem;
}
.sl {
  width: 2.25rem;
}
.sm {
  width: 24rem;
}
.sn {
  width: 128rem;
}
.so {
  width: 150vw;
}
.sp {
  width: 175.5rem;
}
.sq {
  width: 200%;
}
.sr {
  width: 22.875rem;
}
.ss {
  width: 24rem;
}
.st {
  width: 36.0625rem;
}
.su {
  width: 36.125rem;
}
.sv {
  width: 37rem;
}
.sw {
  width: 48rem;
}
.sx {
  width: 50.0625rem;
}
.sy {
  width: 57.875rem;
}
.sz {
  width: 57rem;
}
.ta {
  width: 64rem;
}
.tb {
  width: 68.5625rem;
}
.tc {
  width: 69.25rem;
}
.td {
  width: 72.125rem;
}
.te {
  width: 72.1875rem;
}
.tf {
  width: 76rem;
}
.tg {
  width: 79.125rem;
}
.th {
  width: 80rem;
}
.ti {
  width: 82.0625rem;
}
.tj {
  width: 82.375rem;
}
.tk {
  width: 87.75rem;
}
.tl {
  width: 90rem;
}
.tm {
  width: auto;
}
.tn {
  width: 100%;
}
.to {
  width: 1px;
}
.tp {
  width: 100vw;
}
.tq {
  min-width: 0px;
}
.tr {
  min-width: 12rem;
}
.ts {
  min-width: 100%;
}
.tt {
  min-width: -moz-max-content;
  min-width: max-content;
}
.tu {
  max-width: 0rem;
}
.tv {
  max-width: 42rem;
}
.tw {
  max-width: 48rem;
}
.tx {
  max-width: 56rem;
}
.ty {
  max-width: 64rem;
}
.tz {
  max-width: 72rem;
}
.ua {
  max-width: 80rem;
}
.ub {
  max-width: 100%;
}
.uc {
  max-width: 32rem;
}
.ud {
  max-width: -moz-max-content;
  max-width: max-content;
}
.ue {
  max-width: 28rem;
}
.uf {
  max-width: -moz-min-content;
  max-width: min-content;
}
.ug {
  max-width: none;
}
.uh {
  max-width: 65ch;
}
.ui {
  max-width: 1280px;
}
.uj {
  max-width: 24rem;
}
.uk {
  max-width: 36rem;
}
.ul {
  max-width: 20rem;
}
.um {
  flex: 1 1 0%;
}
.un {
  flex: 1 1 auto;
}
.uo {
  flex: none;
}
.up {
  flex-shrink: 0;
}
.uq {
  flex-shrink: 1;
}
.ur {
  flex-shrink: 0;
}
.us {
  flex-grow: 1;
}
.ut {
  flex-grow: 1;
}
.uu {
  table-layout: fixed;
}
.uv {
  border-collapse: separate;
}
.uw {
  --tw-border-spacing-x: 0px;
  --tw-border-spacing-y: 0px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}
.ux {
  --tw-border-spacing-x: 2rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}
.uy {
  transform-origin: bottom left;
}
.uz {
  transform-origin: bottom right;
}
.va {
  transform-origin: top;
}
.vb {
  transform-origin: top left;
}
.vc {
  transform-origin: top right;
}
.vd {
  --tw-translate-x: -0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.ve {
  --tw-translate-x: -0.125rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.vf {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.vg {
  --tw-translate-x: -33.333333%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.vh {
  --tw-translate-x: -25%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.vi {
  --tw-translate-x: -66.666667%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.vj {
  --tw-translate-x: -0.75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.vk {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.vl {
  --tw-translate-y: -0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.vm {
  --tw-translate-y: -0.125rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.vn {
  --tw-translate-y: -0.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.vo {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.vp {
  --tw-translate-y: -33.333333%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.vq {
  --tw-translate-y: -25%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.vr {
  --tw-translate-y: -4rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.vs {
  --tw-translate-y: -0.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.vt {
  --tw-translate-y: -75%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.vu {
  --tw-translate-y: -2rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.vv {
  --tw-translate-y: -1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.vw {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.vx {
  --tw-translate-x: 0.125rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.vy {
  --tw-translate-x: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.vz {
  --tw-translate-x: 25%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.wa {
  --tw-translate-x: 0.75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.wb {
  --tw-translate-x: 0.875rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.wc {
  --tw-translate-x: 1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.wd {
  --tw-translate-x: 1.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.we {
  --tw-translate-x: 16rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.wf {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.wg {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.wh {
  --tw-translate-y: 0.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.wi {
  --tw-translate-y: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.wj {
  --tw-translate-y: 33.333333%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.wk {
  --tw-translate-y: 25%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.wl {
  --tw-translate-y: 3rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.wm {
  --tw-translate-y: 4rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.wn {
  --tw-translate-y: 0.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.wo {
  --tw-translate-y: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.wp {
  --tw-translate-y: 10rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.wq {
  --tw-translate-y: 1.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.wr {
  --tw-translate-y: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.ws {
  --tw-translate-y: 1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.wt {
  --tw-rotate: -180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.wu {
  --tw-rotate: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.wv {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.ww {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.wx {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.wy {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.wz {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.xa {
  --tw-rotate: 30deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.xb {
  --tw-skew-x: -30deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.xc {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.xd {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.xe {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.xf {
  --tw-scale-x: .75;
  --tw-scale-y: .75;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.xg {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.xh {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.xi {
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.xj {
  cursor: auto;
}
.xk {
  cursor: default;
}
.xl {
  cursor: ew-resize;
}
.xm {
  cursor: nesw-resize;
}
.xn {
  cursor: not-allowed;
}
.xo {
  cursor: ns-resize;
}
.xp {
  cursor: nwse-resize;
}
.xq {
  cursor: pointer;
}
.xr {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.xs {
  -webkit-user-select: all;
     -moz-user-select: all;
          user-select: all;
}
.xt {
  resize: none;
}
.xu {
  resize: both;
}
.xv {
  scroll-padding-top: 2.5rem;
  scroll-padding-bottom: 2.5rem;
}
.xw {
  scroll-padding-top: 0.5rem;
  scroll-padding-bottom: 0.5rem;
}
.xx {
  scroll-padding-top: 0.75rem;
  scroll-padding-bottom: 0.75rem;
}
.xy {
  scroll-padding-top: 1rem;
  scroll-padding-bottom: 1rem;
}
.xz {
  scroll-padding-bottom: 0.5rem;
}
.ya {
  scroll-padding-top: 2.75rem;
}
.yb {
  list-style-type: disc;
}
.yc {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.yd {
  -moz-columns: 2;
       columns: 2;
}
.ye {
  grid-auto-rows: minmax(0, 1fr);
}
.yf {
  grid-auto-rows: min-content;
}
.yg {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.yh {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}
.yi {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.yj {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.yk {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}
.yl {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}
.ym {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}
.yn {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}
.yo {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}
.yp {
  grid-template-rows: repeat(6, minmax(0, 1fr));
}
.yq {
  grid-template-rows: 1fr auto 1fr;
}
.yr {
  flex-direction: column;
}
.ys {
  flex-direction: column-reverse;
}
.yt {
  flex-wrap: wrap;
}
.yu {
  flex-wrap: wrap-reverse;
}
.yv {
  flex-wrap: nowrap;
}
.yw {
  place-items: center;
}
.yx {
  align-items: flex-start;
}
.yy {
  align-items: flex-end;
}
.yz {
  align-items: center;
}
.za {
  align-items: baseline;
}
.zb {
  align-items: stretch;
}
.zc {
  justify-content: flex-start;
}
.zd {
  justify-content: flex-end;
}
.ze {
  justify-content: center;
}
.zf {
  justify-content: space-between;
}
.zg {
  justify-content: space-around;
}
.zh {
  justify-content: stretch;
}
.zi {
  gap: 0px;
}
.zj {
  gap: 0.125rem;
}
.zk {
  gap: 2.5rem;
}
.zl {
  gap: 3rem;
}
.zm {
  gap: 4rem;
}
.zn {
  gap: 0.5rem;
}
.zo {
  gap: 0.75rem;
}
.zp {
  gap: 1rem;
}
.zq {
  gap: 1.25rem;
}
.zr {
  gap: 1.5rem;
}
.zs {
  gap: 1.75rem;
}
.zt {
  gap: 2rem;
}
.zu {
  gap: 1px;
}
.zv {
  -moz-column-gap: 0px;
       column-gap: 0px;
}
.zw {
  -moz-column-gap: 0.125rem;
       column-gap: 0.125rem;
}
.zx {
  -moz-column-gap: 0.25rem;
       column-gap: 0.25rem;
}
.zy {
  -moz-column-gap: 0.375rem;
       column-gap: 0.375rem;
}
.zz {
  -moz-column-gap: 3rem;
       column-gap: 3rem;
}
.aaa {
  -moz-column-gap: 3.5rem;
       column-gap: 3.5rem;
}
.aab {
  -moz-column-gap: 4rem;
       column-gap: 4rem;
}
.aac {
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
}
.aad {
  -moz-column-gap: 0.625rem;
       column-gap: 0.625rem;
}
.aae {
  -moz-column-gap: 5rem;
       column-gap: 5rem;
}
.aaf {
  -moz-column-gap: 0.75rem;
       column-gap: 0.75rem;
}
.aag {
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.aah {
  -moz-column-gap: 1.25rem;
       column-gap: 1.25rem;
}
.aai {
  -moz-column-gap: 1.5rem;
       column-gap: 1.5rem;
}
.aaj {
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}
.aak {
  row-gap: 0.25rem;
}
.aal {
  row-gap: 2.5rem;
}
.aam {
  row-gap: 3rem;
}
.aan {
  row-gap: 3.5rem;
}
.aao {
  row-gap: 4rem;
}
.aap {
  row-gap: 0.5rem;
}
.aaq {
  row-gap: 5rem;
}
.aar {
  row-gap: 0.75rem;
}
.aas {
  row-gap: 1rem;
}
.aat {
  row-gap: 1.25rem;
}
.aau {
  row-gap: 1.5rem;
}
.aav {
  row-gap: 1.75rem;
}
.aaw {
  row-gap: 2rem;
}
.aax > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0px * var(--tw-space-x-reverse));
  margin-left: calc(-0px * calc(1 - var(--tw-space-x-reverse)));
}
.aay > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.125rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.125rem * calc(1 - var(--tw-space-x-reverse)));
}
.aaz > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse)));
}
.aba > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.abb > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1px * var(--tw-space-x-reverse));
  margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
}
.abc > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1px * var(--tw-space-y-reverse));
}
.abd > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}
.abe > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.abf > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(3rem * var(--tw-space-x-reverse));
  margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
}
.abg > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.abh > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.625rem * var(--tw-space-x-reverse));
  margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse)));
}
.abi > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}
.abj > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}
.abk > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
}
.abl > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.abm > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.75rem * var(--tw-space-x-reverse));
  margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse)));
}
.abn > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}
.abo > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}
.abp > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
}
.abq > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3rem * var(--tw-space-y-reverse));
}
.abr > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(4rem * var(--tw-space-y-reverse));
}
.abs > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}
.abt > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(5rem * var(--tw-space-y-reverse));
}
.abu > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(6rem * var(--tw-space-y-reverse));
}
.abv > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}
.abw > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}
.abx > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}
.aby > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}
.abz > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}
.aca > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 1;
}
.acb > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}
.acc > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}
.acd > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
}
.ace > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(21 94 117 / var(--tw-divide-opacity));
}
.acf > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-divide-opacity));
}
.acg > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-divide-opacity));
}
.ach > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-divide-opacity));
}
.aci > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(249 250 251 / var(--tw-divide-opacity));
}
.acj > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-divide-opacity));
}
.ack > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 114 128 / 0.1);
}
.acl > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 114 128 / 0.25);
}
.acm > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-divide-opacity));
}
.acn > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(31 41 55 / var(--tw-divide-opacity));
}
.aco > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 24 39 / 0.1);
}
.acp > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 24 39 / 0.05);
}
.acq > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(99 102 241 / var(--tw-divide-opacity));
}
.acr > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(67 56 202 / var(--tw-divide-opacity));
}
.acs > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(147 51 234 / var(--tw-divide-opacity));
}
.act > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-divide-opacity));
}
.acu > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(231 229 228 / var(--tw-divide-opacity));
}
.acv > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-divide-opacity));
}
.acw > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 255 255 / 0.1);
}
.acx > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 255 255 / 0.05);
}
.acy > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.1;
}
.acz > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.2;
}
.ada > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.75;
}
.adb {
  align-self: flex-start;
}
.adc {
  align-self: flex-end;
}
.add {
  align-self: center;
}
.ade {
  align-self: stretch;
}
.adf {
  overflow: auto;
}
.adg {
  overflow: hidden;
}
.adh {
  overflow: visible;
}
.adi {
  overflow-x: auto;
}
.adj {
  overflow-y: auto;
}
.adk {
  overflow-y: scroll;
}
.adl {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.adm {
  white-space: nowrap;
}
.adn {
  white-space: pre;
}
.ado {
  white-space: pre-wrap;
}
.adp {
  border-radius: 0.25rem;
}
.adq {
  border-radius: 1rem;
}
.adr {
  border-radius: 1.5rem;
}
.ads {
  border-radius: 9999px;
}
.adt {
  border-radius: 0.5rem;
}
.adu {
  border-radius: 0.375rem;
}
.adv {
  border-radius: 0px;
}
.adw {
  border-radius: 0.125rem;
}
.adx {
  border-radius: 0.75rem;
}
.ady {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.adz {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
.aea {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.aeb {
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}
.aec {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.aed {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
.aee {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.aef {
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}
.aeg {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.aeh {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
.aei {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}
.aej {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.aek {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}
.ael {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.aem {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}
.aen {
  border-bottom-left-radius: 1rem;
}
.aeo {
  border-bottom-left-radius: 0.5rem;
}
.aep {
  border-bottom-left-radius: 0.375rem;
}
.aeq {
  border-bottom-right-radius: 1rem;
}
.aer {
  border-bottom-right-radius: 0.5rem;
}
.aes {
  border-bottom-right-radius: 0.375rem;
}
.aet {
  border-top-left-radius: 0.25rem;
}
.aeu {
  border-top-left-radius: 0.5rem;
}
.aev {
  border-top-left-radius: 0.375rem;
}
.aew {
  border-top-left-radius: 0.75rem;
}
.aex {
  border-top-right-radius: 0.5rem;
}
.aey {
  border-top-right-radius: 0.375rem;
}
.aez {
  border-width: 1px;
}
.afa {
  border-width: 0px;
}
.afb {
  border-width: 2px;
}
.afc {
  border-width: 4px;
}
.afd {
  border-left-width: 1px;
  border-right-width: 1px;
}
.afe {
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.aff {
  border-bottom-width: 1px;
}
.afg {
  border-bottom-width: 0px;
}
.afh {
  border-bottom-width: 2px;
}
.afi {
  border-left-width: 1px;
}
.afj {
  border-left-width: 4px;
}
.afk {
  border-right-width: 1px;
}
.afl {
  border-right-width: 0px;
}
.afm {
  border-top-width: 1px;
}
.afn {
  border-top-width: 0px;
}
.afo {
  border-top-width: 2px;
}
.afp {
  border-style: dashed;
}
.afq {
  border-style: none;
}
.afr {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}
.afs {
  --tw-border-opacity: 1;
  border-color: rgb(96 165 250 / var(--tw-border-opacity));
}
.aft {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity));
}
.afu {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}
.afv {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}
.afw {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}
.afx {
  border-color: rgb(75 85 99 / 0.1);
}
.afy {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
}
.afz {
  --tw-border-opacity: 1;
  border-color: rgb(31 41 55 / var(--tw-border-opacity));
}
.aga {
  border-color: rgb(17 24 39 / 0.1);
}
.agb {
  border-color: rgb(17 24 39 / 0.25);
}
.agc {
  border-color: rgb(17 24 39 / 0.05);
}
.agd {
  --tw-border-opacity: 1;
  border-color: rgb(199 210 254 / var(--tw-border-opacity));
}
.age {
  --tw-border-opacity: 1;
  border-color: rgb(165 180 252 / var(--tw-border-opacity));
}
.agf {
  --tw-border-opacity: 1;
  border-color: rgb(129 140 248 / var(--tw-border-opacity));
}
.agg {
  --tw-border-opacity: 1;
  border-color: rgb(99 102 241 / var(--tw-border-opacity));
}
.agh {
  --tw-border-opacity: 1;
  border-color: rgb(79 70 229 / var(--tw-border-opacity));
}
.agi {
  --tw-border-opacity: 1;
  border-color: rgb(67 56 202 / var(--tw-border-opacity));
}
.agj {
  --tw-border-opacity: 1;
  border-color: rgb(55 48 163 / var(--tw-border-opacity));
}
.agk {
  --tw-border-opacity: 1;
  border-color: rgb(49 46 129 / var(--tw-border-opacity));
}
.agl {
  --tw-border-opacity: 1;
  border-color: rgb(254 215 170 / var(--tw-border-opacity));
}
.agm {
  --tw-border-opacity: 1;
  border-color: rgb(236 72 153 / var(--tw-border-opacity));
}
.agn {
  --tw-border-opacity: 1;
  border-color: rgb(168 85 247 / var(--tw-border-opacity));
}
.ago {
  --tw-border-opacity: 1;
  border-color: rgb(147 51 234 / var(--tw-border-opacity));
}
.agp {
  --tw-border-opacity: 1;
  border-color: rgb(186 230 253 / var(--tw-border-opacity));
}
.agq {
  --tw-border-opacity: 1;
  border-color: rgb(14 165 233 / var(--tw-border-opacity));
}
.agr {
  --tw-border-opacity: 1;
  border-color: rgb(7 89 133 / var(--tw-border-opacity));
}
.ags {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
}
.agt {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity));
}
.agu {
  --tw-border-opacity: 1;
  border-color: rgb(231 229 228 / var(--tw-border-opacity));
}
.agv {
  --tw-border-opacity: 1;
  border-color: rgb(214 211 209 / var(--tw-border-opacity));
}
.agw {
  --tw-border-opacity: 1;
  border-color: rgb(68 64 60 / var(--tw-border-opacity));
}
.agx {
  --tw-border-opacity: 1;
  border-color: rgb(20 184 166 / var(--tw-border-opacity));
}
.agy {
  border-color: transparent;
}
.agz {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}
.aha {
  border-color: rgb(255 255 255 / 0.1);
}
.ahb {
  border-color: rgb(255 255 255 / 0.2);
}
.ahc {
  border-color: rgb(255 255 255 / 0.25);
}
.ahd {
  border-color: rgb(255 255 255 / 0.05);
}
.ahe {
  --tw-border-opacity: 1;
  border-color: rgb(250 204 21 / var(--tw-border-opacity));
}
.ahf {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(229 231 235 / var(--tw-border-opacity));
}
.ahg {
  border-bottom-color: rgb(17 24 39 / 0.1);
}
.ahh {
  border-bottom-color: rgb(255 255 255 / 0.2);
}
.ahi {
  border-right-color: rgb(255 255 255 / 0.1);
}
.ahj {
  --tw-border-opacity: 1;
  border-top-color: rgb(243 244 246 / var(--tw-border-opacity));
}
.ahk {
  --tw-border-opacity: 0.1;
}
.ahl {
  --tw-border-opacity: 0.2;
}
.ahm {
  --tw-border-opacity: 0.25;
}
.ahn {
  --tw-bg-opacity: 1;
  background-color: rgb(29 155 240 / var(--tw-bg-opacity));
}
.aho {
  --tw-bg-opacity: 1;
  background-color: rgb(36 41 47 / var(--tw-bg-opacity));
}
.ahp {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}
.ahq {
  background-color: rgb(0 0 0 / 0.1);
}
.ahr {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity));
}
.ahs {
  background-color: rgb(96 165 250 / 0.1);
}
.aht {
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}
.ahu {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}
.ahv {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}
.ahw {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}
.ahx {
  --tw-bg-opacity: 1;
  background-color: rgb(30 58 138 / var(--tw-bg-opacity));
}
.ahy {
  background-color: currentColor;
}
.ahz {
  --tw-bg-opacity: 1;
  background-color: rgb(207 250 254 / var(--tw-bg-opacity));
}
.aia {
  --tw-bg-opacity: 1;
  background-color: rgb(34 211 238 / var(--tw-bg-opacity));
}
.aib {
  --tw-bg-opacity: 1;
  background-color: rgb(236 254 255 / var(--tw-bg-opacity));
}
.aic {
  --tw-bg-opacity: 1;
  background-color: rgb(6 182 212 / var(--tw-bg-opacity));
}
.aid {
  --tw-bg-opacity: 1;
  background-color: rgb(8 145 178 / var(--tw-bg-opacity));
}
.aie {
  --tw-bg-opacity: 1;
  background-color: rgb(14 116 144 / var(--tw-bg-opacity));
}
.aif {
  --tw-bg-opacity: 1;
  background-color: rgb(21 94 117 / var(--tw-bg-opacity));
}
.aig {
  --tw-bg-opacity: 1;
  background-color: rgb(16 185 129 / var(--tw-bg-opacity));
}
.aih {
  background-color: rgb(16 185 129 / 0.2);
}
.aii {
  --tw-bg-opacity: 1;
  background-color: rgb(217 70 239 / var(--tw-bg-opacity));
}
.aij {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}
.aik {
  background-color: rgb(243 244 246 / 0.1);
}
.ail {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}
.aim {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}
.ain {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}
.aio {
  background-color: rgb(156 163 175 / 0.1);
}
.aip {
  background-color: rgb(156 163 175 / 0.05);
}
.aiq {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}
.air {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}
.ais {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}
.ait {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}
.aiu {
  background-color: rgb(55 65 81 / 0.1);
}
.aiv {
  background-color: rgb(55 65 81 / 0.4);
}
.aiw {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}
.aix {
  background-color: rgb(31 41 55 / 0.4);
}
.aiy {
  background-color: rgb(31 41 55 / 0.8);
}
.aiz {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}
.aja {
  background-color: rgb(17 24 39 / 0.1);
}
.ajb {
  background-color: rgb(17 24 39 / 0.05);
}
.ajc {
  background-color: rgb(17 24 39 / 0.8);
}
.ajd {
  background-color: rgb(17 24 39 / 0.9);
}
.aje {
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity));
}
.ajf {
  --tw-bg-opacity: 1;
  background-color: rgb(74 222 128 / var(--tw-bg-opacity));
}
.ajg {
  background-color: rgb(74 222 128 / 0.1);
}
.ajh {
  --tw-bg-opacity: 1;
  background-color: rgb(240 253 244 / var(--tw-bg-opacity));
}
.aji {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}
.ajj {
  background-color: rgb(34 197 94 / 0.1);
}
.ajk {
  --tw-bg-opacity: 1;
  background-color: rgb(224 231 255 / var(--tw-bg-opacity));
}
.ajl {
  --tw-bg-opacity: 1;
  background-color: rgb(199 210 254 / var(--tw-bg-opacity));
}
.ajm {
  --tw-bg-opacity: 1;
  background-color: rgb(129 140 248 / var(--tw-bg-opacity));
}
.ajn {
  background-color: rgb(129 140 248 / 0.1);
}
.ajo {
  --tw-bg-opacity: 1;
  background-color: rgb(238 242 255 / var(--tw-bg-opacity));
}
.ajp {
  --tw-bg-opacity: 1;
  background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}
.ajq {
  background-color: rgb(99 102 241 / 0.1);
}
.ajr {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
}
.ajs {
  background-color: rgb(79 70 229 / 0.1);
}
.ajt {
  --tw-bg-opacity: 1;
  background-color: rgb(67 56 202 / var(--tw-bg-opacity));
}
.aju {
  --tw-bg-opacity: 1;
  background-color: rgb(55 48 163 / var(--tw-bg-opacity));
}
.ajv {
  --tw-bg-opacity: 1;
  background-color: rgb(49 46 129 / var(--tw-bg-opacity));
}
.ajw {
  --tw-bg-opacity: 1;
  background-color: rgb(255 237 213 / var(--tw-bg-opacity));
}
.ajx {
  --tw-bg-opacity: 1;
  background-color: rgb(255 247 237 / var(--tw-bg-opacity));
}
.ajy {
  --tw-bg-opacity: 1;
  background-color: rgb(249 115 22 / var(--tw-bg-opacity));
}
.ajz {
  --tw-bg-opacity: 1;
  background-color: rgb(252 231 243 / var(--tw-bg-opacity));
}
.aka {
  --tw-bg-opacity: 1;
  background-color: rgb(244 114 182 / var(--tw-bg-opacity));
}
.akb {
  background-color: rgb(244 114 182 / 0.1);
}
.akc {
  --tw-bg-opacity: 1;
  background-color: rgb(253 242 248 / var(--tw-bg-opacity));
}
.akd {
  --tw-bg-opacity: 1;
  background-color: rgb(236 72 153 / var(--tw-bg-opacity));
}
.ake {
  --tw-bg-opacity: 1;
  background-color: rgb(219 39 119 / var(--tw-bg-opacity));
}
.akf {
  --tw-bg-opacity: 1;
  background-color: rgb(243 232 255 / var(--tw-bg-opacity));
}
.akg {
  --tw-bg-opacity: 1;
  background-color: rgb(192 132 252 / var(--tw-bg-opacity));
}
.akh {
  background-color: rgb(192 132 252 / 0.1);
}
.aki {
  --tw-bg-opacity: 1;
  background-color: rgb(250 245 255 / var(--tw-bg-opacity));
}
.akj {
  --tw-bg-opacity: 1;
  background-color: rgb(168 85 247 / var(--tw-bg-opacity));
}
.akk {
  --tw-bg-opacity: 1;
  background-color: rgb(147 51 234 / var(--tw-bg-opacity));
}
.akl {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}
.akm {
  --tw-bg-opacity: 1;
  background-color: rgb(248 113 113 / var(--tw-bg-opacity));
}
.akn {
  background-color: rgb(248 113 113 / 0.1);
}
.ako {
  --tw-bg-opacity: 1;
  background-color: rgb(254 242 242 / var(--tw-bg-opacity));
}
.akp {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}
.akq {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}
.akr {
  background-color: rgb(251 113 133 / 0.1);
}
.aks {
  --tw-bg-opacity: 1;
  background-color: rgb(255 241 242 / var(--tw-bg-opacity));
}
.akt {
  --tw-bg-opacity: 1;
  background-color: rgb(244 63 94 / var(--tw-bg-opacity));
}
.aku {
  --tw-bg-opacity: 1;
  background-color: rgb(225 29 72 / var(--tw-bg-opacity));
}
.akv {
  --tw-bg-opacity: 1;
  background-color: rgb(56 189 248 / var(--tw-bg-opacity));
}
.akw {
  --tw-bg-opacity: 1;
  background-color: rgb(240 249 255 / var(--tw-bg-opacity));
}
.akx {
  --tw-bg-opacity: 1;
  background-color: rgb(14 165 233 / var(--tw-bg-opacity));
}
.aky {
  --tw-bg-opacity: 1;
  background-color: rgb(2 132 199 / var(--tw-bg-opacity));
}
.akz {
  --tw-bg-opacity: 1;
  background-color: rgb(3 105 161 / var(--tw-bg-opacity));
}
.ala {
  background-color: rgb(3 105 161 / 0.5);
}
.alb {
  --tw-bg-opacity: 1;
  background-color: rgb(7 89 133 / var(--tw-bg-opacity));
}
.alc {
  --tw-bg-opacity: 1;
  background-color: rgb(12 74 110 / var(--tw-bg-opacity));
}
.ald {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity));
}
.ale {
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity));
}
.alf {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}
.alg {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}
.alh {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 244 / var(--tw-bg-opacity));
}
.ali {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 249 / var(--tw-bg-opacity));
}
.alj {
  --tw-bg-opacity: 1;
  background-color: rgb(28 25 23 / var(--tw-bg-opacity));
}
.alk {
  --tw-bg-opacity: 1;
  background-color: rgb(240 253 250 / var(--tw-bg-opacity));
}
.all {
  --tw-bg-opacity: 1;
  background-color: rgb(20 184 166 / var(--tw-bg-opacity));
}
.alm {
  background-color: transparent;
}
.aln {
  --tw-bg-opacity: 1;
  background-color: rgb(139 92 246 / var(--tw-bg-opacity));
}
.alo {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.alp {
  background-color: rgb(255 255 255 / 0.1);
}
.alq {
  background-color: rgb(255 255 255 / 0.2);
}
.alr {
  background-color: rgb(255 255 255 / 0.05);
}
.als {
  background-color: rgb(255 255 255 / 0.6);
}
.alt {
  --tw-bg-opacity: 1;
  background-color: rgb(254 249 195 / var(--tw-bg-opacity));
}
.alu {
  --tw-bg-opacity: 1;
  background-color: rgb(250 204 21 / var(--tw-bg-opacity));
}
.alv {
  background-color: rgb(250 204 21 / 0.1);
}
.alw {
  --tw-bg-opacity: 1;
  background-color: rgb(254 252 232 / var(--tw-bg-opacity));
}
.alx {
  --tw-bg-opacity: 1;
  background-color: rgb(234 179 8 / var(--tw-bg-opacity));
}
.aly {
  --tw-bg-opacity: 0;
}
.alz {
  --tw-bg-opacity: 0.1;
}
.ama {
  --tw-bg-opacity: 0.25;
}
.amb {
  --tw-bg-opacity: 0.4;
}
.amc {
  --tw-bg-opacity: 0.05;
}
.amd {
  --tw-bg-opacity: 0.5;
}
.ame {
  --tw-bg-opacity: 0.6;
}
.amf {
  --tw-bg-opacity: 0.75;
}
.amg {
  --tw-bg-opacity: 0.8;
}
.amh {
  --tw-bg-opacity: 0.9;
}
.ami {
  background-image: radial-gradient(45rem 50rem at top,#e0e7ff,white);
}
.amj {
  background-image: radial-gradient(50% 100% at top,#e0e7ff,white);
}
.amk {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}
.aml {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
}
.amm {
  background-image: linear-gradient(to left, var(--tw-gradient-stops));
}
.amn {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}
.amo {
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
}
.amp {
  background-image: linear-gradient(to top right, var(--tw-gradient-stops));
}
.amq {
  background-image: none;
}
.amr {
  --tw-gradient-from: #80caff var(--tw-gradient-from-position);
  --tw-gradient-from-position:  ;
  --tw-gradient-to: rgb(128 202 255 / 0)  var(--tw-gradient-from-position);
  --tw-gradient-to-position:  ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.ams {
  --tw-gradient-from: #FF80B5 var(--tw-gradient-from-position);
  --tw-gradient-from-position:  ;
  --tw-gradient-to: rgb(255 128 181 / 0)  var(--tw-gradient-from-position);
  --tw-gradient-to-position:  ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.amt {
  --tw-gradient-from: #ff4694 var(--tw-gradient-from-position);
  --tw-gradient-from-position:  ;
  --tw-gradient-to: rgb(255 70 148 / 0)  var(--tw-gradient-from-position);
  --tw-gradient-to-position:  ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.amu {
  --tw-gradient-from: #ff80b5 var(--tw-gradient-from-position);
  --tw-gradient-from-position:  ;
  --tw-gradient-to: rgb(255 128 181 / 0)  var(--tw-gradient-from-position);
  --tw-gradient-to-position:  ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.amv {
  --tw-gradient-from: #000 var(--tw-gradient-from-position);
  --tw-gradient-from-position:  ;
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-from-position);
  --tw-gradient-to-position:  ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.amw {
  --tw-gradient-from: #eff6ff var(--tw-gradient-from-position);
  --tw-gradient-from-position:  ;
  --tw-gradient-to: rgb(239 246 255 / 0)  var(--tw-gradient-from-position);
  --tw-gradient-to-position:  ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.amx {
  --tw-gradient-from: #0891b2 var(--tw-gradient-from-position);
  --tw-gradient-from-position:  ;
  --tw-gradient-to: rgb(8 145 178 / 0)  var(--tw-gradient-from-position);
  --tw-gradient-to-position:  ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.amy {
  --tw-gradient-from: #f3f4f6 var(--tw-gradient-from-position);
  --tw-gradient-from-position:  ;
  --tw-gradient-to: rgb(243 244 246 / 0)  var(--tw-gradient-from-position);
  --tw-gradient-to-position:  ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.amz {
  --tw-gradient-from: #1f2937 var(--tw-gradient-from-position);
  --tw-gradient-from-position:  ;
  --tw-gradient-to: rgb(31 41 55 / 0)  var(--tw-gradient-from-position);
  --tw-gradient-to-position:  ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.ana {
  --tw-gradient-from: #111827 var(--tw-gradient-from-position);
  --tw-gradient-from-position:  ;
  --tw-gradient-to: rgb(17 24 39 / 0)  var(--tw-gradient-from-position);
  --tw-gradient-to-position:  ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.anb {
  --tw-gradient-from: rgb(224 231 255 / 0.2) var(--tw-gradient-from-position);
  --tw-gradient-from-position:  ;
  --tw-gradient-to: rgb(224 231 255 / 0)  var(--tw-gradient-from-position);
  --tw-gradient-to-position:  ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.anc {
  --tw-gradient-from: #fb923c var(--tw-gradient-from-position);
  --tw-gradient-from-position:  ;
  --tw-gradient-to: rgb(251 146 60 / 0)  var(--tw-gradient-from-position);
  --tw-gradient-to-position:  ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.and {
  --tw-gradient-from: #f97316 var(--tw-gradient-from-position);
  --tw-gradient-from-position:  ;
  --tw-gradient-to: rgb(249 115 22 / 0)  var(--tw-gradient-from-position);
  --tw-gradient-to-position:  ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.ane {
  --tw-gradient-from: #d8b4fe var(--tw-gradient-from-position);
  --tw-gradient-from-position:  ;
  --tw-gradient-to: rgb(216 180 254 / 0)  var(--tw-gradient-from-position);
  --tw-gradient-to-position:  ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.anf {
  --tw-gradient-from: #9333ea var(--tw-gradient-from-position);
  --tw-gradient-from-position:  ;
  --tw-gradient-to: rgb(147 51 234 / 0)  var(--tw-gradient-from-position);
  --tw-gradient-to-position:  ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.ang {
  --tw-gradient-from: #6b21a8 var(--tw-gradient-from-position);
  --tw-gradient-from-position:  ;
  --tw-gradient-to: rgb(107 33 168 / 0)  var(--tw-gradient-from-position);
  --tw-gradient-to-position:  ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.anh {
  --tw-gradient-from: #e11d48 var(--tw-gradient-from-position);
  --tw-gradient-from-position:  ;
  --tw-gradient-to: rgb(225 29 72 / 0)  var(--tw-gradient-from-position);
  --tw-gradient-to-position:  ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.ani {
  --tw-gradient-from: #075985 var(--tw-gradient-from-position);
  --tw-gradient-from-position:  ;
  --tw-gradient-to: rgb(7 89 133 / 0)  var(--tw-gradient-from-position);
  --tw-gradient-to-position:  ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.anj {
  --tw-gradient-from: #99f6e4 var(--tw-gradient-from-position);
  --tw-gradient-from-position:  ;
  --tw-gradient-to: rgb(153 246 228 / 0)  var(--tw-gradient-from-position);
  --tw-gradient-to-position:  ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.ank {
  --tw-gradient-from: #14b8a6 var(--tw-gradient-from-position);
  --tw-gradient-from-position:  ;
  --tw-gradient-to: rgb(20 184 166 / 0)  var(--tw-gradient-from-position);
  --tw-gradient-to-position:  ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.anl {
  --tw-gradient-from: transparent var(--tw-gradient-from-position);
  --tw-gradient-from-position:  ;
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-from-position);
  --tw-gradient-to-position:  ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.anm {
  --tw-gradient-from: #fff var(--tw-gradient-from-position);
  --tw-gradient-from-position:  ;
  --tw-gradient-to: rgb(255 255 255 / 0)  var(--tw-gradient-from-position);
  --tw-gradient-to-position:  ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.ann {
  --tw-gradient-via-position:  ;
  --tw-gradient-to: rgb(17 24 39 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-to-position:  ;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 24 39 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to);
}
.ano {
  --tw-gradient-via-position:  ;
  --tw-gradient-to: rgb(225 29 72 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-to-position:  ;
  --tw-gradient-stops: var(--tw-gradient-from), #e11d48 var(--tw-gradient-via-position), var(--tw-gradient-to);
}
.anp {
  --tw-gradient-via-position:  ;
  --tw-gradient-to: rgb(255 255 255 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-to-position:  ;
  --tw-gradient-stops: var(--tw-gradient-from), #fff var(--tw-gradient-via-position), var(--tw-gradient-to);
}
.anq {
  --tw-gradient-to: #4f46e5 var(--tw-gradient-to-position);
  --tw-gradient-to-position:  ;
}
.anr {
  --tw-gradient-to: #776fff var(--tw-gradient-to-position);
  --tw-gradient-to-position:  ;
}
.ans {
  --tw-gradient-to: #9089FC var(--tw-gradient-to-position);
  --tw-gradient-to-position:  ;
}
.ant {
  --tw-gradient-to: #9089fc var(--tw-gradient-to-position);
  --tw-gradient-to-position:  ;
}
.anu {
  --tw-gradient-to: #000 var(--tw-gradient-to-position);
  --tw-gradient-to-position:  ;
}
.anv {
  --tw-gradient-to: #22d3ee var(--tw-gradient-to-position);
  --tw-gradient-to-position:  ;
}
.anw {
  --tw-gradient-to: #0891b2 var(--tw-gradient-to-position);
  --tw-gradient-to-position:  ;
}
.anx {
  --tw-gradient-to: #0e7490 var(--tw-gradient-to-position);
  --tw-gradient-to-position:  ;
}
.any {
  --tw-gradient-to: #f9fafb var(--tw-gradient-to-position);
  --tw-gradient-to-position:  ;
}
.anz {
  --tw-gradient-to: #4ade80 var(--tw-gradient-to-position);
  --tw-gradient-to-position:  ;
}
.aoa {
  --tw-gradient-to: #a5b4fc var(--tw-gradient-to-position);
  --tw-gradient-to-position:  ;
}
.aob {
  --tw-gradient-to: #4f46e5 var(--tw-gradient-to-position);
  --tw-gradient-to-position:  ;
}
.aoc {
  --tw-gradient-to: #4338ca var(--tw-gradient-to-position);
  --tw-gradient-to-position:  ;
}
.aod {
  --tw-gradient-to: #ec4899 var(--tw-gradient-to-position);
  --tw-gradient-to-position:  ;
}
.aoe {
  --tw-gradient-to: #0d9488 var(--tw-gradient-to-position);
  --tw-gradient-to-position:  ;
}
.aof {
  --tw-gradient-to: #fff var(--tw-gradient-to-position);
  --tw-gradient-to-position:  ;
}
.aog {
  background-size: cover;
}
.aoh {
  -webkit-background-clip: text;
          background-clip: text;
}
.aoi {
  background-position: center;
}
.aoj {
  background-origin: border-box;
}
.aok {
  fill: #60a5fa;
}
.aol {
  fill: #3b82f6;
}
.aom {
  fill: currentColor;
}
.aon {
  fill: #d1d5db;
}
.aoo {
  fill: #9ca3af;
}
.aop {
  fill: #f9fafb;
}
.aoq {
  fill: rgb(31 41 55 / 0.2);
}
.aor {
  fill: #111827;
}
.aos {
  fill: #4ade80;
}
.aot {
  fill: #22c55e;
}
.aou {
  fill: #818cf8;
}
.aov {
  fill: #6366f1;
}
.aow {
  fill: #f472b6;
}
.aox {
  fill: #ec4899;
}
.aoy {
  fill: #c084fc;
}
.aoz {
  fill: #a855f7;
}
.apa {
  fill: #f87171;
}
.apb {
  fill: #ef4444;
}
.apc {
  fill: #fff;
}
.apd {
  fill: rgb(255 255 255 / 0.5);
}
.ape {
  fill: #facc15;
}
.apf {
  fill: #eab308;
}
.apg {
  stroke: rgb(29 78 216 / 0.5);
}
.aph {
  stroke: rgb(30 64 175 / 0.5);
}
.api {
  stroke: #e5e7eb;
}
.apj {
  stroke: rgb(75 85 99 / 0.5);
}
.apk {
  stroke: #374151;
}
.apl {
  stroke: rgb(55 65 81 / 0.5);
}
.apm {
  stroke: rgb(17 24 39 / 0.1);
}
.apn {
  stroke: rgb(17 24 39 / 0.05);
}
.apo {
  stroke: rgb(21 128 61 / 0.5);
}
.app {
  stroke: rgb(22 101 52 / 0.5);
}
.apq {
  stroke: rgb(79 70 229 / 0.5);
}
.apr {
  stroke: rgb(67 56 202 / 0.5);
}
.aps {
  stroke: rgb(190 24 93 / 0.5);
}
.apt {
  stroke: rgb(157 23 77 / 0.5);
}
.apu {
  stroke: rgb(220 38 38 / 0.5);
}
.apv {
  stroke: rgb(185 28 28 / 0.5);
}
.apw {
  stroke: rgb(124 58 237 / 0.5);
}
.apx {
  stroke: rgb(109 40 217 / 0.5);
}
.apy {
  stroke: rgb(255 255 255 / 0.1);
}
.apz {
  stroke: rgb(255 255 255 / 0.2);
}
.aqa {
  stroke: rgb(161 98 7 / 0.5);
}
.aqb {
  stroke: rgb(133 77 14 / 0.5);
}
.aqc {
  stroke-width: 2;
}
.aqd {
  -o-object-fit: contain;
     object-fit: contain;
}
.aqe {
  -o-object-fit: cover;
     object-fit: cover;
}
.aqf {
  -o-object-position: center;
     object-position: center;
}
.aqg {
  -o-object-position: left;
     object-position: left;
}
.aqh {
  -o-object-position: left top;
     object-position: left top;
}
.aqi {
  -o-object-position: right;
     object-position: right;
}
.aqj {
  -o-object-position: top;
     object-position: top;
}
.aqk {
  padding: 0px;
}
.aql {
  padding: 0.125rem;
}
.aqm {
  padding: 0.25rem;
}
.aqn {
  padding: 0.375rem;
}
.aqo {
  padding: 2.5rem;
}
.aqp {
  padding: 3rem;
}
.aqq {
  padding: 0.5rem;
}
.aqr {
  padding: 0.625rem;
}
.aqs {
  padding: 0.75rem;
}
.aqt {
  padding: 1rem;
}
.aqu {
  padding: 1.25rem;
}
.aqv {
  padding: 1.5rem;
}
.aqw {
  padding: 2rem;
}
.aqx {
  padding: 1px;
}
.aqy {
  padding-left: 0px;
  padding-right: 0px;
}
.aqz {
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}
.ara {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.arb {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}
.arc {
  padding-left: 3rem;
  padding-right: 3rem;
}
.ard {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.are {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
.arf {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.arg {
  padding-left: 0.875rem;
  padding-right: 0.875rem;
}
.arh {
  padding-left: 9rem;
  padding-right: 9rem;
}
.ari {
  padding-left: 1rem;
  padding-right: 1rem;
}
.arj {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.ark {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.arl {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}
.arm {
  padding-left: 2rem;
  padding-right: 2rem;
}
.arn {
  padding-top: 0px;
  padding-bottom: 0px;
}
.aro {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}
.arp {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.arq {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}
.arr {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.ars {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.art {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}
.aru {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.arv {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.arw {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
.arx {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.ary {
  padding-top: 6rem;
  padding-bottom: 6rem;
}
.arz {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.asa {
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}
.asb {
  padding-top: 8rem;
  padding-bottom: 8rem;
}
.asc {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.asd {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.ase {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.asf {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.asg {
  padding-top: 1px;
  padding-bottom: 1px;
}
.ash {
  padding-bottom: 0px;
}
.asi {
  padding-bottom: 0.25rem;
}
.asj {
  padding-bottom: 0.375rem;
}
.ask {
  padding-bottom: 2.5rem;
}
.asl {
  padding-bottom: 3rem;
}
.asm {
  padding-bottom: 3.5rem;
}
.asn {
  padding-bottom: 4rem;
}
.aso {
  padding-bottom: 0.5rem;
}
.asp {
  padding-bottom: 5rem;
}
.asq {
  padding-bottom: 6rem;
}
.asr {
  padding-bottom: 0.75rem;
}
.ass {
  padding-bottom: 8rem;
}
.ast {
  padding-bottom: 9rem;
}
.asu {
  padding-bottom: 1rem;
}
.asv {
  padding-bottom: 1.25rem;
}
.asw {
  padding-bottom: 1.5rem;
}
.asx {
  padding-bottom: 16rem;
}
.asy {
  padding-bottom: 2rem;
}
.asz {
  padding-bottom: 20rem;
}
.ata {
  padding-bottom: 2.25rem;
}
.atb {
  padding-bottom: 24rem;
}
.atc {
  padding-bottom: 1px;
}
.atd {
  padding-left: 0px;
}
.ate {
  padding-left: 0.25rem;
}
.atf {
  padding-left: 0.375rem;
}
.atg {
  padding-left: 2.5rem;
}
.ath {
  padding-left: 2.75rem;
}
.ati {
  padding-left: 3rem;
}
.atj {
  padding-left: 4rem;
}
.atk {
  padding-left: 0.5rem;
}
.atl {
  padding-left: 5rem;
}
.atm {
  padding-left: 0.75rem;
}
.atn {
  padding-left: 1rem;
}
.ato {
  padding-left: 1.25rem;
}
.atp {
  padding-left: 1.5rem;
}
.atq {
  padding-left: 1.75rem;
}
.atr {
  padding-left: 2rem;
}
.ats {
  padding-left: 2.25rem;
}
.att {
  padding-right: 0px;
}
.atu {
  padding-right: 0.25rem;
}
.atv {
  padding-right: 0.375rem;
}
.atw {
  padding-right: 2.5rem;
}
.atx {
  padding-right: 3rem;
}
.aty {
  padding-right: 3.5rem;
}
.atz {
  padding-right: 4rem;
}
.aua {
  padding-right: 0.5rem;
}
.aub {
  padding-right: 5rem;
}
.auc {
  padding-right: 0.75rem;
}
.aud {
  padding-right: 0.875rem;
}
.aue {
  padding-right: 1rem;
}
.auf {
  padding-right: 1.25rem;
}
.aug {
  padding-right: 1.5rem;
}
.auh {
  padding-right: 1.75rem;
}
.aui {
  padding-right: 2rem;
}
.auj {
  padding-right: 2.25rem;
}
.auk {
  padding-top: 0px;
}
.aul {
  padding-top: 0.125rem;
}
.aum {
  padding-top: 0.25rem;
}
.aun {
  padding-top: 0.375rem;
}
.auo {
  padding-top: 2.5rem;
}
.aup {
  padding-top: 2.75rem;
}
.auq {
  padding-top: 3rem;
}
.aur {
  padding-top: 3.5rem;
}
.aus {
  padding-top: 4rem;
}
.aut {
  padding-top: 0.5rem;
}
.auu {
  padding-top: 0.625rem;
}
.auv {
  padding-top: 5rem;
}
.auw {
  padding-top: 6rem;
}
.aux {
  padding-top: 0.75rem;
}
.auy {
  padding-top: 0.875rem;
}
.auz {
  padding-top: 8rem;
}
.ava {
  padding-top: 9rem;
}
.avb {
  padding-top: 1rem;
}
.avc {
  padding-top: 12rem;
}
.avd {
  padding-top: 1.25rem;
}
.ave {
  padding-top: 1.5rem;
}
.avf {
  padding-top: 16rem;
}
.avg {
  padding-top: 2rem;
}
.avh {
  padding-top: 20rem;
}
.avi {
  padding-top: 7%;
}
.avj {
  padding-top: 1px;
}
.avk {
  text-align: left;
}
.avl {
  text-align: center;
}
.avm {
  text-align: right;
}
.avn {
  vertical-align: top;
}
.avo {
  vertical-align: middle;
}
.avp {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}
.avq {
  font-family: Inter var, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-feature-settings: "cv02", "cv03", "cv04", "cv11";
}
.avr {
  font-size: 1.5rem;
  line-height: 2rem;
}
.avs {
  font-size: 1.875rem;
  line-height: 2.25rem;
}
.avt {
  font-size: 2.25rem;
  line-height: 2.5rem;
}
.avu {
  font-size: 3rem;
  line-height: 1;
}
.avv {
  font-size: 3.75rem;
  line-height: 1;
}
.avw {
  font-size: 0.625rem;
}
.avx {
  font-size: 0.8125rem;
}
.avy {
  font-size: 1rem;
  line-height: 1.5rem;
}
.avz {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.awa {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.awb {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.awc {
  font-size: 0.75rem;
  line-height: 1rem;
}
.awd {
  font-weight: 700;
}
.awe {
  font-weight: 500;
}
.awf {
  font-weight: 400;
}
.awg {
  font-weight: 600;
}
.awh {
  text-transform: uppercase;
}
.awi {
  text-transform: capitalize;
}
.awj {
  font-style: italic;
}
.awk {
  font-style: normal;
}
.awl {
  --tw-numeric-spacing: tabular-nums;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}
.awm {
  line-height: 2.5rem;
}
.awn {
  line-height: 1rem;
}
.awo {
  line-height: 1.25rem;
}
.awp {
  line-height: 1.5rem;
}
.awq {
  line-height: 1.75rem;
}
.awr {
  line-height: 2rem;
}
.aws {
  line-height: 2.25rem;
}
.awt {
  line-height: 1;
}
.awu {
  line-height: 1.5;
}
.awv {
  line-height: 1.25;
}
.aww {
  letter-spacing: 0em;
}
.awx {
  letter-spacing: -0.025em;
}
.awy {
  letter-spacing: 0.025em;
}
.awz {
  --tw-text-opacity: 1;
  color: rgb(125 211 252 / var(--tw-text-opacity));
}
.axa {
  --tw-text-opacity: 1;
  color: rgb(191 219 254 / var(--tw-text-opacity));
}
.axb {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity));
}
.axc {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}
.axd {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}
.axe {
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity));
}
.axf {
  --tw-text-opacity: 1;
  color: rgb(30 58 138 / var(--tw-text-opacity));
}
.axg {
  --tw-text-opacity: 1;
  color: rgb(207 250 254 / var(--tw-text-opacity));
}
.axh {
  --tw-text-opacity: 1;
  color: rgb(165 243 252 / var(--tw-text-opacity));
}
.axi {
  --tw-text-opacity: 1;
  color: rgb(8 145 178 / var(--tw-text-opacity));
}
.axj {
  --tw-text-opacity: 1;
  color: rgb(14 116 144 / var(--tw-text-opacity));
}
.axk {
  --tw-text-opacity: 1;
  color: rgb(21 94 117 / var(--tw-text-opacity));
}
.axl {
  --tw-text-opacity: 1;
  color: rgb(22 78 99 / var(--tw-text-opacity));
}
.axm {
  --tw-text-opacity: 1;
  color: rgb(110 231 183 / var(--tw-text-opacity));
}
.axn {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}
.axo {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}
.axp {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
.axq {
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity));
}
.axr {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}
.axs {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}
.axt {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}
.axu {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}
.axv {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}
.axw {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity));
}
.axx {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}
.axy {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}
.axz {
  --tw-text-opacity: 1;
  color: rgb(21 128 61 / var(--tw-text-opacity));
}
.aya {
  --tw-text-opacity: 1;
  color: rgb(22 101 52 / var(--tw-text-opacity));
}
.ayb {
  --tw-text-opacity: 1;
  color: rgb(224 231 255 / var(--tw-text-opacity));
}
.ayc {
  --tw-text-opacity: 1;
  color: rgb(199 210 254 / var(--tw-text-opacity));
}
.ayd {
  --tw-text-opacity: 1;
  color: rgb(165 180 252 / var(--tw-text-opacity));
}
.aye {
  --tw-text-opacity: 1;
  color: rgb(129 140 248 / var(--tw-text-opacity));
}
.ayf {
  --tw-text-opacity: 1;
  color: rgb(238 242 255 / var(--tw-text-opacity));
}
.ayg {
  --tw-text-opacity: 1;
  color: rgb(99 102 241 / var(--tw-text-opacity));
}
.ayh {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}
.ayi {
  --tw-text-opacity: 1;
  color: rgb(67 56 202 / var(--tw-text-opacity));
}
.ayj {
  --tw-text-opacity: 1;
  color: rgb(55 48 163 / var(--tw-text-opacity));
}
.ayk {
  --tw-text-opacity: 1;
  color: rgb(49 46 129 / var(--tw-text-opacity));
}
.ayl {
  --tw-text-opacity: 1;
  color: rgb(255 247 237 / var(--tw-text-opacity));
}
.aym {
  --tw-text-opacity: 1;
  color: rgb(249 115 22 / var(--tw-text-opacity));
}
.ayn {
  --tw-text-opacity: 1;
  color: rgb(234 88 12 / var(--tw-text-opacity));
}
.ayo {
  --tw-text-opacity: 1;
  color: rgb(194 65 12 / var(--tw-text-opacity));
}
.ayp {
  --tw-text-opacity: 1;
  color: rgb(124 45 18 / var(--tw-text-opacity));
}
.ayq {
  --tw-text-opacity: 1;
  color: rgb(244 114 182 / var(--tw-text-opacity));
}
.ayr {
  --tw-text-opacity: 1;
  color: rgb(236 72 153 / var(--tw-text-opacity));
}
.ays {
  --tw-text-opacity: 1;
  color: rgb(190 24 93 / var(--tw-text-opacity));
}
.ayt {
  --tw-text-opacity: 1;
  color: rgb(157 23 77 / var(--tw-text-opacity));
}
.ayu {
  --tw-text-opacity: 1;
  color: rgb(233 213 255 / var(--tw-text-opacity));
}
.ayv {
  --tw-text-opacity: 1;
  color: rgb(192 132 252 / var(--tw-text-opacity));
}
.ayw {
  --tw-text-opacity: 1;
  color: rgb(168 85 247 / var(--tw-text-opacity));
}
.ayx {
  --tw-text-opacity: 1;
  color: rgb(147 51 234 / var(--tw-text-opacity));
}
.ayy {
  --tw-text-opacity: 1;
  color: rgb(126 34 206 / var(--tw-text-opacity));
}
.ayz {
  --tw-text-opacity: 1;
  color: rgb(88 28 135 / var(--tw-text-opacity));
}
.aza {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}
.azb {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}
.azc {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}
.azd {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
}
.aze {
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity));
}
.azf {
  --tw-text-opacity: 1;
  color: rgb(127 29 29 / var(--tw-text-opacity));
}
.azg {
  --tw-text-opacity: 1;
  color: rgb(255 228 230 / var(--tw-text-opacity));
}
.azh {
  --tw-text-opacity: 1;
  color: rgb(254 205 211 / var(--tw-text-opacity));
}
.azi {
  --tw-text-opacity: 1;
  color: rgb(251 113 133 / var(--tw-text-opacity));
}
.azj {
  --tw-text-opacity: 1;
  color: rgb(244 63 94 / var(--tw-text-opacity));
}
.azk {
  --tw-text-opacity: 1;
  color: rgb(225 29 72 / var(--tw-text-opacity));
}
.azl {
  --tw-text-opacity: 1;
  color: rgb(190 18 60 / var(--tw-text-opacity));
}
.azm {
  --tw-text-opacity: 1;
  color: rgb(224 242 254 / var(--tw-text-opacity));
}
.azn {
  --tw-text-opacity: 1;
  color: rgb(186 230 253 / var(--tw-text-opacity));
}
.azo {
  --tw-text-opacity: 1;
  color: rgb(2 132 199 / var(--tw-text-opacity));
}
.azp {
  --tw-text-opacity: 1;
  color: rgb(3 105 161 / var(--tw-text-opacity));
}
.azq {
  --tw-text-opacity: 1;
  color: rgb(12 74 110 / var(--tw-text-opacity));
}
.azr {
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity));
}
.azs {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity));
}
.azt {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}
.azu {
  --tw-text-opacity: 1;
  color: rgb(71 85 105 / var(--tw-text-opacity));
}
.azv {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity));
}
.azw {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
}
.azx {
  --tw-text-opacity: 1;
  color: rgb(231 229 228 / var(--tw-text-opacity));
}
.azy {
  --tw-text-opacity: 1;
  color: rgb(168 162 158 / var(--tw-text-opacity));
}
.azz {
  --tw-text-opacity: 1;
  color: rgb(120 113 108 / var(--tw-text-opacity));
}
.baa {
  --tw-text-opacity: 1;
  color: rgb(68 64 60 / var(--tw-text-opacity));
}
.bab {
  --tw-text-opacity: 1;
  color: rgb(28 25 23 / var(--tw-text-opacity));
}
.bac {
  --tw-text-opacity: 1;
  color: rgb(153 246 228 / var(--tw-text-opacity));
}
.bad {
  --tw-text-opacity: 1;
  color: rgb(240 253 250 / var(--tw-text-opacity));
}
.bae {
  --tw-text-opacity: 1;
  color: rgb(20 184 166 / var(--tw-text-opacity));
}
.baf {
  --tw-text-opacity: 1;
  color: rgb(15 118 110 / var(--tw-text-opacity));
}
.bag {
  color: transparent;
}
.bah {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.bai {
  color: rgb(255 255 255 / 0.6);
}
.baj {
  color: rgb(255 255 255 / 0.7);
}
.bak {
  --tw-text-opacity: 1;
  color: rgb(253 224 71 / var(--tw-text-opacity));
}
.bal {
  --tw-text-opacity: 1;
  color: rgb(250 204 21 / var(--tw-text-opacity));
}
.bam {
  --tw-text-opacity: 1;
  color: rgb(234 179 8 / var(--tw-text-opacity));
}
.ban {
  --tw-text-opacity: 1;
  color: rgb(161 98 7 / var(--tw-text-opacity));
}
.bao {
  --tw-text-opacity: 1;
  color: rgb(133 77 14 / var(--tw-text-opacity));
}
.bap {
  --tw-text-opacity: 1;
}
.baq {
  --tw-text-opacity: 0.4;
}
.bar {
  --tw-text-opacity: 0.75;
}
.bas {
  text-decoration-line: underline;
}
.bat {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.bau::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(107 114 128 / var(--tw-placeholder-opacity));
}
.bau::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(107 114 128 / var(--tw-placeholder-opacity));
}
.bav::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(148 163 184 / var(--tw-placeholder-opacity));
}
.bav::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(148 163 184 / var(--tw-placeholder-opacity));
}
.baw::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(120 113 108 / var(--tw-placeholder-opacity));
}
.baw::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(120 113 108 / var(--tw-placeholder-opacity));
}
.bax {
  opacity: 0;
}
.bay {
  opacity: 1;
}
.baz {
  opacity: 0.2;
}
.bba {
  opacity: 0.25;
}
.bbb {
  opacity: 0.3;
}
.bbc {
  opacity: 0.4;
}
.bbd {
  opacity: 0.5;
}
.bbe {
  opacity: 0.75;
}
.bbf {
  opacity: 0.9;
}
.bbg {
  opacity: 0.45;
}
.bbh {
  mix-blend-mode: multiply;
}
.bbi {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.bbj {
  --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.bbk {
  --tw-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.bbl {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.bbm {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.bbn {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.bbo {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.bx-shadow{
  -webkit-box-shadow: 0px 0px 15px -6px #000000; 
  box-shadow: 0px 0px 15px -6px #000000;
}

.bx-shadow-blue{
  -webkit-box-shadow: 0px 0px 22px -1px #1C64F2; 
box-shadow: 0px 0px 22px -1px #1C64F2;
}
.bbp {
  --tw-shadow-color: rgb(79 70 229 / 0.1);
  --tw-shadow: var(--tw-shadow-colored);
}
.bbq {
  outline-style: solid;
}
.bbr {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.bbs {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.bbt {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.bbu {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.bbv {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.bbw {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.bbx {
  --tw-ring-inset: inset;
}
.bby {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity));
}
.bbz {
  --tw-ring-color: rgb(0 0 0 / 0.1);
}
.bca {
  --tw-ring-color: rgb(96 165 250 / 0.3);
}
.bcb {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity));
}
.bcc {
  --tw-ring-color: rgb(29 78 216 / 0.1);
}
.bcd {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(243 244 246 / var(--tw-ring-opacity));
}
.bce {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(229 231 235 / var(--tw-ring-opacity));
}
.bcf {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity));
}
.bcg {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(156 163 175 / var(--tw-ring-opacity));
}
.bch {
  --tw-ring-color: rgb(156 163 175 / 0.1);
}
.bci {
  --tw-ring-color: rgb(156 163 175 / 0.2);
}
.bcj {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(107 114 128 / var(--tw-ring-opacity));
}
.bck {
  --tw-ring-color: rgb(107 114 128 / 0.1);
}
.bcl {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(55 65 81 / var(--tw-ring-opacity));
}
.bcm {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(31 41 55 / var(--tw-ring-opacity));
}
.bcn {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(17 24 39 / var(--tw-ring-opacity));
}
.bco {
  --tw-ring-color: rgb(17 24 39 / 0.1);
}
.bcp {
  --tw-ring-color: rgb(17 24 39 / 0.05);
}
.bcq {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(34 197 94 / var(--tw-ring-opacity));
}
.bcr {
  --tw-ring-color: rgb(34 197 94 / 0.2);
}
.bcs {
  --tw-ring-color: rgb(22 163 74 / 0.2);
}
.bct {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(199 210 254 / var(--tw-ring-opacity));
}
.bcu {
  --tw-ring-color: rgb(129 140 248 / 0.3);
}
.bcv {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(238 242 255 / var(--tw-ring-opacity));
}
.bcw {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(99 102 241 / var(--tw-ring-opacity));
}
.bcx {
  --tw-ring-color: rgb(99 102 241 / 0.2);
}
.bcy {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(79 70 229 / var(--tw-ring-opacity));
}
.bcz {
  --tw-ring-color: rgb(79 70 229 / 0.1);
}
.bda {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(67 56 202 / var(--tw-ring-opacity));
}
.bdb {
  --tw-ring-color: rgb(67 56 202 / 0.1);
}
.bdc {
  --tw-ring-color: rgb(244 114 182 / 0.2);
}
.bdd {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(236 72 153 / var(--tw-ring-opacity));
}
.bde {
  --tw-ring-color: rgb(190 24 93 / 0.1);
}
.bdf {
  --tw-ring-color: rgb(192 132 252 / 0.3);
}
.bdg {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(168 85 247 / var(--tw-ring-opacity));
}
.bdh {
  --tw-ring-color: rgb(126 34 206 / 0.1);
}
.bdi {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(252 165 165 / var(--tw-ring-opacity));
}
.bdj {
  --tw-ring-color: rgb(248 113 113 / 0.2);
}
.bdk {
  --tw-ring-color: rgb(220 38 38 / 0.1);
}
.bdl {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(203 213 225 / var(--tw-ring-opacity));
}
.bdm {
  --tw-ring-color: transparent;
}
.bdn {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity));
}
.bdo {
  --tw-ring-color: rgb(255 255 255 / 0.1);
}
.bdp {
  --tw-ring-color: rgb(255 255 255 / 0.05);
}
.bdq {
  --tw-ring-color: rgb(250 204 21 / 0.2);
}
.bdr {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(234 179 8 / var(--tw-ring-opacity));
}
.bds {
  --tw-ring-color: rgb(202 138 4 / 0.2);
}
.bdt {
  --tw-ring-opacity: 1;
}
.bdu {
  --tw-ring-opacity: 0.2;
}
.bdv {
  --tw-ring-opacity: 0.05;
}
.bdw {
  --tw-ring-offset-width: 1px;
}
.bdx {
  --tw-ring-offset-width: 2px;
}
.bdy {
  --tw-blur: blur(40px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.bdz {
  --tw-blur: blur(64px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.bea {
  --tw-blur: blur(118px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.beb {
  --tw-brightness: brightness(1.25);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.bec {
  --tw-brightness: brightness(1.5);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.bed {
  --tw-drop-shadow: drop-shadow(0 20px 13px rgb(0 0 0 / 0.03)) drop-shadow(0 8px 5px rgb(0 0 0 / 0.08));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.bee {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.bef {
  --tw-saturate: saturate(0);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.beg {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.beh {
  --tw-backdrop-blur: blur(8px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.bei {
  --tw-backdrop-blur: blur(12px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.bej {
  --tw-backdrop-blur: blur(24px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.bek {
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.bel {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.bem {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.ben {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.beo {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.bep {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.beq {
  transition-duration: 100ms;
}
.ber {
  transition-duration: 150ms;
}
.bes {
  transition-duration: 200ms;
}
.bet {
  transition-duration: 300ms;
}
.beu {
  transition-duration: 500ms;
}
.bev {
  transition-duration: 75ms;
}
.bew {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}
.bex {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.bey {
  transition-timing-function: linear;
}
.bez {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.bfa {
  -webkit-clip-path: inset(0);
          clip-path: inset(0);
}
.bfb {
  -webkit-mask-image: radial-gradient(100% 100% at top right,white,transparent);
          mask-image: radial-gradient(100% 100% at top right,white,transparent);
}
.bfc {
  -webkit-mask-image: radial-gradient(32rem 32rem at center,white,transparent);
          mask-image: radial-gradient(32rem 32rem at center,white,transparent);
}
.bfd {
  -webkit-mask-image: radial-gradient(50% 45% at 50% 55%,white,transparent);
          mask-image: radial-gradient(50% 45% at 50% 55%,white,transparent);
}
.bfe {
  -webkit-mask-image: radial-gradient(64rem 64rem at 111.5rem 0%,white,transparent);
          mask-image: radial-gradient(64rem 64rem at 111.5rem 0%,white,transparent);
}
.bff {
  -webkit-mask-image: radial-gradient(64rem 64rem at top,white,transparent);
          mask-image: radial-gradient(64rem 64rem at top,white,transparent);
}
.bfg {
  -webkit-mask-image: radial-gradient(closest-side,white,transparent);
          mask-image: radial-gradient(closest-side,white,transparent);
}
@media (min-width: 640px) {

  .bfh {
    --tw-aspect-h: 1;
  }

  .bfi {
    --tw-aspect-h: 2;
  }

  .bfj {
    --tw-aspect-h: 3;
  }

  .bfk {
    --tw-aspect-h: 5;
  }

  .bfl {
    --tw-aspect-h: 7;
  }

  .bfm {
    position: relative;
    padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
    --tw-aspect-w: 1;
  }

  .bfm > * {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .bfn {
    position: relative;
    padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
    --tw-aspect-w: 16;
  }

  .bfn > * {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .bfo {
    position: relative;
    padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
    --tw-aspect-w: 2;
  }

  .bfo > * {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .bfp {
    position: relative;
    padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
    --tw-aspect-w: 4;
  }

  .bfp > * {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .bfq {
    position: relative;
    padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
    --tw-aspect-w: 5;
  }

  .bfq > * {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .bfr {
    position: static;
    padding-bottom: 0;
  }

  .bfr > * {
    position: static;
    height: auto;
    width: auto;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
}
@media (min-width: 768px) {

  .bfs {
    --tw-aspect-h: 1;
  }

  .bft {
    position: relative;
    padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
    --tw-aspect-w: 2;
  }

  .bft > * {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
@media (min-width: 1024px) {

  .bfu {
    --tw-aspect-h: 1;
  }

  .bfv {
    --tw-aspect-h: 4;
  }

  .bfw {
    --tw-aspect-h: 6;
  }

  .bfx {
    position: relative;
    padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
    --tw-aspect-w: 1;
  }

  .bfx > * {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .bfy {
    position: relative;
    padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
    --tw-aspect-w: 3;
  }

  .bfy > * {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .bfz {
    position: relative;
    padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
    --tw-aspect-w: 5;
  }

  .bfz > * {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .bga {
    position: static;
    padding-bottom: 0;
  }

  .bga > * {
    position: static;
    height: auto;
    width: auto;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
}
@media (min-width: 1280px) {

  .bgb {
    --tw-aspect-h: 8;
  }

  .bgc {
    position: relative;
    padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
    --tw-aspect-w: 7;
  }

  .bgc > * {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
.bgd::-moz-placeholder {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
.bgd::placeholder {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
.bge::-moz-placeholder {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}
.bge::placeholder {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}
.bgf::-moz-placeholder {
  --tw-text-opacity: 1;
  color: rgb(199 210 254 / var(--tw-text-opacity));
}
.bgf::placeholder {
  --tw-text-opacity: 1;
  color: rgb(199 210 254 / var(--tw-text-opacity));
}
.bgg::-moz-placeholder {
  --tw-text-opacity: 1;
  color: rgb(252 165 165 / var(--tw-text-opacity));
}
.bgg::placeholder {
  --tw-text-opacity: 1;
  color: rgb(252 165 165 / var(--tw-text-opacity));
}
.bgh::-moz-placeholder {
  --tw-text-opacity: 1;
  color: rgb(224 242 254 / var(--tw-text-opacity));
}
.bgh::placeholder {
  --tw-text-opacity: 1;
  color: rgb(224 242 254 / var(--tw-text-opacity));
}
.bgi::-moz-placeholder {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity));
}
.bgi::placeholder {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity));
}
.bgj::-moz-placeholder {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.bgj::placeholder {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.bgk::-moz-placeholder {
  color: rgb(255 255 255 / 0.75);
}
.bgk::placeholder {
  color: rgb(255 255 255 / 0.75);
}
.bgl::before {
  content: var(--tw-content);
  display: block;
}
.bgm:nth-child(even) {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}
.bgn:focus-within {
  z-index: 10;
}
.bgo:focus-within {
  border-width: 1px;
}
.bgp:focus-within {
  --tw-border-opacity: 1;
  border-color: rgb(99 102 241 / var(--tw-border-opacity));
}
.bgq:focus-within {
  --tw-border-opacity: 1;
  border-color: rgb(79 70 229 / var(--tw-border-opacity));
}
.bgr:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}
.bgs:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}
.bgt:focus-within {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
.bgu:focus-within {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}
.bgv:focus-within {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}
.bgw:focus-within {
  opacity: 1;
}
.bgx:focus-within {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.bgy:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.bgz:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.bha:focus-within {
  --tw-ring-inset: inset;
}
.bhb:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(37 99 235 / var(--tw-ring-opacity));
}
.bhc:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(6 182 212 / var(--tw-ring-opacity));
}
.bhd:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(99 102 241 / var(--tw-ring-opacity));
}
.bhe:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(79 70 229 / var(--tw-ring-opacity));
}
.bhf:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(236 72 153 / var(--tw-ring-opacity));
}
.bhg:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity));
}
.bhh:focus-within {
  --tw-ring-offset-width: 2px;
}
.bhi:focus-within {
  --tw-ring-offset-color: #f3f4f6;
}
.bhj:focus-within {
  --tw-ring-offset-color: #111827;
}
.bhk:hover {
  z-index: 10;
}
.bhl:hover {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}
.bhm:hover {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}
.bhn:hover {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}
.bho:hover {
  --tw-border-opacity: 1;
  border-color: rgb(55 48 163 / var(--tw-border-opacity));
}
.bhp:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}
.bhq:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity));
}
.bhr:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(191 219 254 / var(--tw-bg-opacity));
}
.bhs:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}
.bht:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}
.bhu:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}
.bhv:hover {
  background-color: rgb(37 99 235 / 0.2);
}
.bhw:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}
.bhx:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(207 250 254 / var(--tw-bg-opacity));
}
.bhy:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(236 254 255 / var(--tw-bg-opacity));
}
.bhz:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(6 182 212 / var(--tw-bg-opacity));
}
.bia:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(8 145 178 / var(--tw-bg-opacity));
}
.bib:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(14 116 144 / var(--tw-bg-opacity));
}
.bic:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}
.bid:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}
.bie:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}
.bif:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}
.big:hover {
  background-color: rgb(156 163 175 / 0.1);
}
.bih:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}
.bii:hover {
  background-color: rgb(107 114 128 / 0.2);
}
.bij:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}
.bik:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}
.bil:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}
.bim:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity));
}
.bin:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}
.bio:hover {
  background-color: rgb(22 163 74 / 0.2);
}
.bip:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(224 231 255 / var(--tw-bg-opacity));
}
.biq:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(129 140 248 / var(--tw-bg-opacity));
}
.bir:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(238 242 255 / var(--tw-bg-opacity));
}
.bis:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}
.bit:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
}
.biu:hover {
  background-color: rgb(79 70 229 / 0.2);
}
.biv:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(67 56 202 / var(--tw-bg-opacity));
}
.biw:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(55 48 163 / var(--tw-bg-opacity));
}
.bix:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(49 46 129 / var(--tw-bg-opacity));
}
.biy:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(254 215 170 / var(--tw-bg-opacity));
}
.biz:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(252 231 243 / var(--tw-bg-opacity));
}
.bja:hover {
  background-color: rgb(219 39 119 / 0.2);
}
.bjb:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(233 213 255 / var(--tw-bg-opacity));
}
.bjc:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(250 245 255 / var(--tw-bg-opacity));
}
.bjd:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(168 85 247 / var(--tw-bg-opacity));
}
.bje:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(147 51 234 / var(--tw-bg-opacity));
}
.bjf:hover {
  background-color: rgb(147 51 234 / 0.2);
}
.bjg:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(126 34 206 / var(--tw-bg-opacity));
}
.bjh:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(248 113 113 / var(--tw-bg-opacity));
}
.bji:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}
.bjj:hover {
  background-color: rgb(220 38 38 / 0.2);
}
.bjk:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 228 230 / var(--tw-bg-opacity));
}
.bjl:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(244 63 94 / var(--tw-bg-opacity));
}
.bjm:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(225 29 72 / var(--tw-bg-opacity));
}
.bjn:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(190 18 60 / var(--tw-bg-opacity));
}
.bjo:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(56 189 248 / var(--tw-bg-opacity));
}
.bjp:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(2 132 199 / var(--tw-bg-opacity));
}
.bjq:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(7 89 133 / var(--tw-bg-opacity));
}
.bjr:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}
.bjs:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity));
}
.bjt:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 244 / var(--tw-bg-opacity));
}
.bju:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(231 229 228 / var(--tw-bg-opacity));
}
.bjv:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 249 / var(--tw-bg-opacity));
}
.bjw:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(240 253 250 / var(--tw-bg-opacity));
}
.bjx:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(13 148 136 / var(--tw-bg-opacity));
}
.bjy:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.bjz:hover {
  background-color: rgb(255 255 255 / 0.2);
}
.bka:hover {
  background-color: rgb(202 138 4 / 0.2);
}
.bkb:hover {
  --tw-bg-opacity: 0.1;
}
.bkc:hover {
  --tw-bg-opacity: 1;
}
.bkd:hover {
  --tw-bg-opacity: 0.2;
}
.bke:hover {
  --tw-bg-opacity: 0.5;
}
.bkf:hover {
  --tw-bg-opacity: 0.7;
}
.bkg:hover {
  --tw-bg-opacity: 0.75;
}
.bkh:hover {
  --tw-gradient-from: #7e22ce var(--tw-gradient-from-position);
  --tw-gradient-from-position:  ;
  --tw-gradient-to: rgb(126 34 206 / 0)  var(--tw-gradient-from-position);
  --tw-gradient-to-position:  ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.bki:hover {
  --tw-gradient-from: #0d9488 var(--tw-gradient-from-position);
  --tw-gradient-from-position:  ;
  --tw-gradient-to: rgb(13 148 136 / 0)  var(--tw-gradient-from-position);
  --tw-gradient-to-position:  ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.bkj:hover {
  --tw-gradient-to: #0e7490 var(--tw-gradient-to-position);
  --tw-gradient-to-position:  ;
}
.bkk:hover {
  --tw-gradient-to: #4338ca var(--tw-gradient-to-position);
  --tw-gradient-to-position:  ;
}
.bkl:hover {
  --tw-gradient-to: #db2777 var(--tw-gradient-to-position);
  --tw-gradient-to-position:  ;
}
.bkm:hover {
  --tw-text-opacity: 1;
  color: rgb(219 234 254 / var(--tw-text-opacity));
}
.bkn:hover {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}
.bko:hover {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}
.bkp:hover {
  --tw-text-opacity: 1;
  color: rgb(207 250 254 / var(--tw-text-opacity));
}
.bkq:hover {
  --tw-text-opacity: 1;
  color: rgb(6 182 212 / var(--tw-text-opacity));
}
.bkr:hover {
  --tw-text-opacity: 1;
  color: rgb(8 145 178 / var(--tw-text-opacity));
}
.bks:hover {
  --tw-text-opacity: 1;
  color: rgb(22 78 99 / var(--tw-text-opacity));
}
.bkt:hover {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
.bku:hover {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}
.bkv:hover {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}
.bkw:hover {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
.bkx:hover {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}
.bky:hover {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}
.bkz:hover {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}
.bla:hover {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}
.blb:hover {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}
.blc:hover {
  --tw-text-opacity: 1;
  color: rgb(224 231 255 / var(--tw-text-opacity));
}
.bld:hover {
  --tw-text-opacity: 1;
  color: rgb(165 180 252 / var(--tw-text-opacity));
}
.ble:hover {
  --tw-text-opacity: 1;
  color: rgb(238 242 255 / var(--tw-text-opacity));
}
.blf:hover {
  --tw-text-opacity: 1;
  color: rgb(99 102 241 / var(--tw-text-opacity));
}
.blg:hover {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}
.blh:hover {
  --tw-text-opacity: 1;
  color: rgb(67 56 202 / var(--tw-text-opacity));
}
.bli:hover {
  --tw-text-opacity: 1;
  color: rgb(49 46 129 / var(--tw-text-opacity));
}
.blj:hover {
  --tw-text-opacity: 1;
  color: rgb(124 45 18 / var(--tw-text-opacity));
}
.blk:hover {
  --tw-text-opacity: 1;
  color: rgb(168 85 247 / var(--tw-text-opacity));
}
.bll:hover {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}
.blm:hover {
  --tw-text-opacity: 1;
  color: rgb(244 63 94 / var(--tw-text-opacity));
}
.bln:hover {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}
.blo:hover {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
}
.blp:hover {
  --tw-text-opacity: 1;
  color: rgb(214 211 209 / var(--tw-text-opacity));
}
.blq:hover {
  --tw-text-opacity: 1;
  color: rgb(120 113 108 / var(--tw-text-opacity));
}
.blr:hover {
  --tw-text-opacity: 1;
  color: rgb(28 25 23 / var(--tw-text-opacity));
}
.bls:hover {
  --tw-text-opacity: 1;
  color: rgb(204 251 241 / var(--tw-text-opacity));
}
.blt:hover {
  --tw-text-opacity: 1;
  color: rgb(15 118 110 / var(--tw-text-opacity));
}
.blu:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.blv:hover {
  --tw-text-opacity: 1;
  color: rgb(250 204 21 / var(--tw-text-opacity));
}
.blw:hover {
  --tw-text-opacity: 1;
  color: rgb(202 138 4 / var(--tw-text-opacity));
}
.blx:hover {
  text-decoration-line: underline;
}
.bly:hover {
  opacity: 1;
}
.blz:hover {
  opacity: 0.75;
}
.bma:hover {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.bmb:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(156 163 175 / var(--tw-ring-opacity));
}
.bmc:hover {
  --tw-ring-color: rgb(17 24 39 / 0.2);
}
.bmd:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(165 180 252 / var(--tw-ring-opacity));
}
.bme:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(67 56 202 / var(--tw-ring-opacity));
}
.bmf:hover {
  --tw-ring-color: rgb(255 255 255 / 0.2);
}
.bmg:focus {
  position: relative;
}
.bmh:focus {
  z-index: 10;
}
.bmi:focus {
  z-index: 20;
}
.bmj:focus {
  border-width: 0px;
}
.bmk:focus {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
}
.bml:focus {
  --tw-border-opacity: 1;
  border-color: rgb(99 102 241 / var(--tw-border-opacity));
}
.bmm:focus {
  --tw-border-opacity: 1;
  border-color: rgb(79 70 229 / var(--tw-border-opacity));
}
.bmn:focus {
  --tw-border-opacity: 1;
  border-color: rgb(249 115 22 / var(--tw-border-opacity));
}
.bmo:focus {
  --tw-border-opacity: 1;
  border-color: rgb(168 85 247 / var(--tw-border-opacity));
}
.bmp:focus {
  --tw-border-opacity: 1;
  border-color: rgb(244 63 94 / var(--tw-border-opacity));
}
.bmq:focus {
  --tw-border-opacity: 1;
  border-color: rgb(20 184 166 / var(--tw-border-opacity));
}
.bmr:focus {
  border-color: transparent;
}
.bms:focus {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}
.bmt:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}
.bmu:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(12 74 110 / var(--tw-bg-opacity));
}
.bmv:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.bmw:focus {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}
.bmx:focus::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(156 163 175 / var(--tw-placeholder-opacity));
}
.bmx:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(156 163 175 / var(--tw-placeholder-opacity));
}
.bmy:focus {
  opacity: 1;
}
.bmz:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.bna:focus {
  outline-offset: 0px;
}
.bnb:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.bnc:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.bnd:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.bne:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.bnf:focus {
  --tw-ring-inset: inset;
}
.bng:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity));
}
.bnh:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(37 99 235 / var(--tw-ring-opacity));
}
.bni:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(34 211 238 / var(--tw-ring-opacity));
}
.bnj:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(6 182 212 / var(--tw-ring-opacity));
}
.bnk:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(8 145 178 / var(--tw-ring-opacity));
}
.bnl:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(17 24 39 / var(--tw-ring-opacity));
}
.bnm:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(74 222 128 / var(--tw-ring-opacity));
}
.bnn:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(34 197 94 / var(--tw-ring-opacity));
}
.bno:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(22 163 74 / var(--tw-ring-opacity));
}
.bnp:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(165 180 252 / var(--tw-ring-opacity));
}
.bnq:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(99 102 241 / var(--tw-ring-opacity));
}
.bnr:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(79 70 229 / var(--tw-ring-opacity));
}
.bns:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(249 115 22 / var(--tw-ring-opacity));
}
.bnt:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(236 72 153 / var(--tw-ring-opacity));
}
.bnu:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(219 39 119 / var(--tw-ring-opacity));
}
.bnv:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(168 85 247 / var(--tw-ring-opacity));
}
.bnw:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(147 51 234 / var(--tw-ring-opacity));
}
.bnx:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(239 68 68 / var(--tw-ring-opacity));
}
.bny:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(244 63 94 / var(--tw-ring-opacity));
}
.bnz:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(14 165 233 / var(--tw-ring-opacity));
}
.boa:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(20 184 166 / var(--tw-ring-opacity));
}
.bob:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity));
}
.boc:focus {
  --tw-ring-opacity: 1;
}
.bod:focus {
  --tw-ring-opacity: 0.25;
}
.boe:focus {
  --tw-ring-opacity: 0.5;
}
.bof:focus {
  --tw-ring-offset-width: 1px;
}
.bog:focus {
  --tw-ring-offset-width: 2px;
}
.boh:focus {
  --tw-ring-offset-width: 4px;
}
.boi:focus {
  --tw-ring-offset-color: #0e7490;
}
.boj:focus {
  --tw-ring-offset-color: #f3f4f6;
}
.bok:focus {
  --tw-ring-offset-color: #f9fafb;
}
.bol:focus {
  --tw-ring-offset-color: #1f2937;
}
.bom:focus {
  --tw-ring-offset-color: #111827;
}
.bon:focus {
  --tw-ring-offset-color: #f0fdf4;
}
.boo:focus {
  --tw-ring-offset-color: #4f46e5;
}
.bop:focus {
  --tw-ring-offset-color: #4338ca;
}
.boq:focus {
  --tw-ring-offset-color: #312e81;
}
.bor:focus {
  --tw-ring-offset-color: #f43f5e;
}
.bos:focus {
  --tw-ring-offset-color: #0ea5e9;
}
.bot:focus {
  --tw-ring-offset-color: #0c4a6e;
}
.bou:focus {
  --tw-ring-offset-color: #fff;
}
.bov:focus::-moz-placeholder {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
.bov:focus::placeholder {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
.bow:focus::-moz-placeholder {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}
.bow:focus::placeholder {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}
.box:focus-visible {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.boy:focus-visible {
  outline-style: solid;
}
.boz:focus-visible {
  outline-width: 2px;
}
.bpa:focus-visible {
  outline-offset: 0px;
}
.bpb:focus-visible {
  outline-offset: 2px;
}
.bpc:focus-visible {
  outline-offset: -4px;
}
.bpd:focus-visible {
  outline-color: #1D9BF0;
}
.bpe:focus-visible {
  outline-color: #24292F;
}
.bpf:focus-visible {
  outline-color: #2563eb;
}
.bpg:focus-visible {
  outline-color: #0891b2;
}
.bph:focus-visible {
  outline-color: #111827;
}
.bpi:focus-visible {
  outline-color: #818cf8;
}
.bpj:focus-visible {
  outline-color: #6366f1;
}
.bpk:focus-visible {
  outline-color: #4f46e5;
}
.bpl:focus-visible {
  outline-color: #9333ea;
}
.bpm:focus-visible {
  outline-color: #e11d48;
}
.bpn:focus-visible {
  outline-color: #0ea5e9;
}
.bpo:focus-visible {
  outline-color: #0369a1;
}
.bpp:focus-visible {
  outline-color: #fff;
}
.bpq:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.bpr:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(168 85 247 / var(--tw-ring-opacity));
}
.bps:focus-visible {
  --tw-ring-offset-width: 2px;
}
.bpt:focus-visible {
  --tw-ring-offset-color: #f9fafb;
}
.bpu:active {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.bpv:active {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(17 24 39 / var(--tw-ring-opacity));
}
.bpw:active {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(79 70 229 / var(--tw-ring-opacity));
}
.bpx:active {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(14 165 233 / var(--tw-ring-opacity));
}
.bpy:active {
  --tw-ring-offset-width: 2px;
}
.bpz:disabled {
  cursor: not-allowed;
}
.bqa:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}
.bqb:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}
.bqc:disabled {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}
.bqd:disabled {
  opacity: 0.3;
}
.bqe:disabled {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(229 231 235 / var(--tw-ring-opacity));
}
.bqf:hover:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.bqg:hover .bqh {
  visibility: visible;
}
.bqg:hover .bqi {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}
.bqg:hover .bqj {
  --tw-border-opacity: 1;
  border-color: rgb(79 70 229 / var(--tw-border-opacity));
}
.bqg:hover .bqk {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}
.bqg:hover .bql {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}
.bqg:hover .bqm {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}
.bqg:hover .bqn {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}
.bqg:hover .bqo {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}
.bqg:hover .bqp {
  --tw-bg-opacity: 1;
  background-color: rgb(55 48 163 / var(--tw-bg-opacity));
}
.bqg:hover .bqq {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.bqg:hover .bqr {
  stroke: rgb(29 78 216 / 0.75);
}
.bqg:hover .bqs {
  stroke: rgb(30 64 175 / 0.75);
}
.bqg:hover .bqt {
  stroke: rgb(75 85 99 / 0.75);
}
.bqg:hover .bqu {
  stroke: rgb(55 65 81 / 0.75);
}
.bqg:hover .bqv {
  stroke: rgb(21 128 61 / 0.75);
}
.bqg:hover .bqw {
  stroke: rgb(22 101 52 / 0.75);
}
.bqg:hover .bqx {
  stroke: rgb(79 70 229 / 0.75);
}
.bqg:hover .bqy {
  stroke: rgb(67 56 202 / 0.75);
}
.bqg:hover .bqz {
  stroke: rgb(190 24 93 / 0.75);
}
.bqg:hover .bra {
  stroke: rgb(157 23 77 / 0.75);
}
.bqg:hover .brb {
  stroke: rgb(220 38 38 / 0.75);
}
.bqg:hover .brc {
  stroke: rgb(185 28 28 / 0.75);
}
.bqg:hover .brd {
  stroke: rgb(124 58 237 / 0.75);
}
.bqg:hover .bre {
  stroke: rgb(109 40 217 / 0.75);
}
.bqg:hover .brf {
  stroke: rgb(161 98 7 / 0.75);
}
.bqg:hover .brg {
  stroke: rgb(133 77 14 / 0.75);
}
.bqg:hover .brh {
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity));
}
.bqg:hover .bri {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
.bqg:hover .brj {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}
.bqg:hover .brk {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}
.bqg:hover .brl {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
.bqg:hover .brm {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}
.bqg:hover .brn {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}
.bqg:hover .bro {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}
.bqg:hover .brp {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}
.bqg:hover .brq {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}
.bqg:hover .brr {
  --tw-text-opacity: 1;
  color: rgb(99 102 241 / var(--tw-text-opacity));
}
.bqg:hover .brs {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}
.bqg:hover .brt {
  --tw-text-opacity: 1;
  color: rgb(67 56 202 / var(--tw-text-opacity));
}
.bqg:hover .bru {
  --tw-text-opacity: 1;
  color: rgb(55 48 163 / var(--tw-text-opacity));
}
.bqg:hover .brv {
  --tw-text-opacity: 1;
  color: rgb(49 46 129 / var(--tw-text-opacity));
}
.bqg:hover .brw {
  --tw-text-opacity: 1;
  color: rgb(190 24 93 / var(--tw-text-opacity));
}
.bqg:hover .brx {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}
.bqg:hover .bry {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity));
}
.bqg:hover .brz {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
}
.bqg:hover .bsa {
  --tw-text-opacity: 1;
  color: rgb(20 184 166 / var(--tw-text-opacity));
}
.bqg:hover .bsb {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.bqg:hover .bsc {
  opacity: 1;
}
.bqg:hover .bsd {
  opacity: 0.75;
}
.bqg:focus .bse {
  visibility: visible;
}
.bsf:hover ~ .bsg {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity));
}
.bsf:hover ~ .bsh {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(156 163 175 / var(--tw-ring-opacity));
}
.bsf:focus ~ .bsi {
  border-top-width: 2px;
}
.bsf:focus ~ .bsj {
  --tw-border-opacity: 1;
  border-color: rgb(79 70 229 / var(--tw-border-opacity));
}
.bsf:focus ~ .bsk {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.bsf:focus ~ .bsl {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(37 99 235 / var(--tw-ring-opacity));
}
.bsf:focus ~ .bsm {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(79 70 229 / var(--tw-ring-opacity));
}
.bsf:focus ~ .bsn {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(14 165 233 / var(--tw-ring-opacity));
}
@media (prefers-color-scheme: dark) {

  .bso {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity));
  }
}
@media (min-width: 640px) {

  .bsp {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .bsq {
    position: static;
  }

  .bsr {
    position: fixed;
  }

  .bss {
    position: absolute;
  }

  .bst {
    position: relative;
  }

  .bsu {
    inset: 0px;
  }

  .bsv {
    inset: auto;
  }

  .bsw {
    top: 0px;
    bottom: 0px;
  }

  .bsx {
    bottom: -16rem;
  }

  .bsy {
    left: -10rem;
  }

  .bsz {
    top: -2.5rem;
  }

  .bta {
    top: -3rem;
  }

  .btb {
    top: -20rem;
  }

  .btc {
    bottom: 0px;
  }

  .btd {
    left: 50%;
  }

  .bte {
    left: 3rem;
  }

  .btf {
    left: calc(50% + 36rem);
  }

  .btg {
    left: calc(50% - 18rem);
  }

  .bth {
    left: calc(50% - 30rem);
  }

  .bti {
    left: calc(50% - 40rem);
  }

  .btj {
    left: auto;
  }

  .btk {
    left: 100%;
  }

  .btl {
    right: 0px;
  }

  .btm {
    right: 50%;
  }

  .btn {
    right: 1.5rem;
  }

  .bto {
    right: calc(50% - 6rem);
  }

  .btp {
    top: 0px;
  }

  .btq {
    top: 1.5rem;
  }

  .btr {
    top: 2rem;
  }

  .bts {
    top: -20rem;
  }

  .btt {
    top: -28rem;
  }

  .btu {
    top: calc(100% - 30rem);
  }

  .btv {
    top: auto;
  }

  .btw {
    z-index: -10;
  }

  .btx {
    order: 1;
  }

  .bty {
    order: 2;
  }

  .btz {
    order: 3;
  }

  .bua {
    order: -9999;
  }

  .bub {
    order: 9999;
  }

  .buc {
    order: 0;
  }

  .bud {
    grid-column: span 1 / span 1;
  }

  .bue {
    grid-column: span 12 / span 12;
  }

  .buf {
    grid-column: span 2 / span 2;
  }

  .bug {
    grid-column: span 3 / span 3;
  }

  .buh {
    grid-column: span 4 / span 4;
  }

  .bui {
    grid-column: span 6 / span 6;
  }

  .buj {
    grid-column: span 7 / span 7;
  }

  .buk {
    grid-column: span 8 / span 8;
  }

  .bul {
    grid-column: span 9 / span 9;
  }

  .bum {
    grid-column-start: 1;
  }

  .bun {
    grid-column-start: 2;
  }

  .buo {
    grid-column-start: 3;
  }

  .bup {
    grid-column-start: 6;
  }

  .buq {
    grid-column-start: auto;
  }

  .bur {
    grid-row: span 2 / span 2;
  }

  .bus {
    grid-row-start: 1;
  }

  .but {
    margin: 0px;
  }

  .buu {
    margin-left: -0px;
    margin-right: -0px;
  }

  .buv {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .buw {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .bux {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .buy {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .buz {
    margin-left: 0px;
    margin-right: 0px;
  }

  .bva {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  .bvb {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .bvc {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .bvd {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .bve {
    margin-left: auto;
    margin-right: auto;
  }

  .bvf {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }

  .bvg {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .bvh {
    margin-bottom: -6rem;
  }

  .bvi {
    margin-bottom: -12rem;
  }

  .bvj {
    margin-bottom: -16rem;
  }

  .bvk {
    margin-left: -0.25rem;
  }

  .bvl {
    margin-left: -2.5rem;
  }

  .bvm {
    margin-left: -1rem;
  }

  .bvn {
    margin-left: -20rem;
  }

  .bvo {
    margin-left: -24rem;
  }

  .bvp {
    margin-right: -0.25rem;
  }

  .bvq {
    margin-right: -0.5rem;
  }

  .bvr {
    margin-right: -20rem;
  }

  .bvs {
    margin-top: -2.5rem;
  }

  .bvt {
    margin-top: -4rem;
  }

  .bvu {
    margin-top: -5rem;
  }

  .bvv {
    margin-top: -8rem;
  }

  .bvw {
    margin-top: -11rem;
  }

  .bvx {
    margin-top: -2rem;
  }

  .bvy {
    margin-bottom: 0px;
  }

  .bvz {
    margin-bottom: 2.5rem;
  }

  .bwa {
    margin-bottom: 1rem;
  }

  .bwb {
    margin-bottom: 1.25rem;
  }

  .bwc {
    margin-bottom: 2rem;
  }

  .bwd {
    margin-left: 0px;
  }

  .bwe {
    margin-left: 2.5rem;
  }

  .bwf {
    margin-left: 4rem;
  }

  .bwg {
    margin-left: 0.5rem;
  }

  .bwh {
    margin-left: 0.75rem;
  }

  .bwi {
    margin-left: 8rem;
  }

  .bwj {
    margin-left: 1rem;
  }

  .bwk {
    margin-left: 10rem;
  }

  .bwl {
    margin-left: 1.5rem;
  }

  .bwm {
    margin-left: 1.75rem;
  }

  .bwn {
    margin-left: 2rem;
  }

  .bwo {
    margin-left: auto;
  }

  .bwp {
    margin-right: 0px;
  }

  .bwq {
    margin-right: 2.5rem;
  }

  .bwr {
    margin-right: 5rem;
  }

  .bws {
    margin-right: 7rem;
  }

  .bwt {
    margin-right: 1rem;
  }

  .bwu {
    margin-right: 1.5rem;
  }

  .bwv {
    margin-top: 0px;
  }

  .bww {
    margin-top: 0.25rem;
  }

  .bwx {
    margin-top: 0.375rem;
  }

  .bwy {
    margin-top: 2.5rem;
  }

  .bwz {
    margin-top: 3rem;
  }

  .bxa {
    margin-top: 4rem;
  }

  .bxb {
    margin-top: 0.5rem;
  }

  .bxc {
    margin-top: 5rem;
  }

  .bxd {
    margin-top: 6rem;
  }

  .bxe {
    margin-top: 0.75rem;
  }

  .bxf {
    margin-top: 8rem;
  }

  .bxg {
    margin-top: 1rem;
  }

  .bxh {
    margin-top: 10rem;
  }

  .bxi {
    margin-top: 12rem;
  }

  .bxj {
    margin-top: 1.25rem;
  }

  .bxk {
    margin-top: 14rem;
  }

  .bxl {
    margin-top: 1.5rem;
  }

  .bxm {
    margin-top: 16rem;
  }

  .bxn {
    margin-top: 2rem;
  }

  .bxo {
    display: block;
  }

  .bxp {
    display: inline-block;
  }

  .bxq {
    display: inline;
  }

  .bxr {
    display: flex;
  }

  .bxs {
    display: inline-flex;
  }

  .bxt {
    display: table-cell;
  }

  .bxu {
    display: grid;
  }

  .bxv {
    display: inline-grid;
  }

  .bxw {
    display: none;
  }

  .bxx {
    aspect-ratio: 16/9;
  }

  .bxy {
    aspect-ratio: 2/1;
  }

  .bxz {
    height: 2.5rem;
  }

  .bya {
    height: 3rem;
  }

  .byb {
    height: 66.666667%;
  }

  .byc {
    height: 5rem;
  }

  .byd {
    height: 8rem;
  }

  .bye {
    height: 10rem;
  }

  .byf {
    height: 12rem;
  }

  .byg {
    height: 14rem;
  }

  .byh {
    height: 1.5rem;
  }

  .byi {
    height: 16rem;
  }

  .byj {
    height: 1.75rem;
  }

  .byk {
    height: 18rem;
  }

  .byl {
    height: 20rem;
  }

  .bym {
    height: 24rem;
  }

  .byn {
    height: auto;
  }

  .byo {
    height: 100%;
  }

  .byp {
    width: 0px;
  }

  .byq {
    width: 50%;
  }

  .byr {
    width: 33.333333%;
  }

  .bys {
    width: 16.666667%;
  }

  .byt {
    width: 2.5rem;
  }

  .byu {
    width: 91.666667%;
  }

  .byv {
    width: 3rem;
  }

  .byw {
    width: 40%;
  }

  .byx {
    width: 75%;
  }

  .byy {
    width: 8rem;
  }

  .byz {
    width: 33.333333%;
  }

  .bza {
    width: 10rem;
  }

  .bzb {
    width: 12rem;
  }

  .bzc {
    width: 14rem;
  }

  .bzd {
    width: 16rem;
  }

  .bze {
    width: 20rem;
  }

  .bzf {
    width: 57rem;
  }

  .bzg {
    width: 68.5625rem;
  }

  .bzh {
    width: 72.1875rem;
  }

  .bzi {
    width: auto;
  }

  .bzj {
    width: 100%;
  }

  .bzk {
    width: 1px;
  }

  .bzl {
    min-width: 0px;
  }

  .bzm {
    max-width: 42rem;
  }

  .bzn {
    max-width: 48rem;
  }

  .bzo {
    max-width: 64rem;
  }

  .bzp {
    max-width: 80rem;
  }

  .bzq {
    max-width: 480px;
  }

  .bzr {
    max-width: 32rem;
  }

  .bzs {
    max-width: 28rem;
  }

  .bzt {
    max-width: none;
  }

  .bzu {
    max-width: 24rem;
  }

  .bzv {
    max-width: 36rem;
  }

  .bzw {
    max-width: 20rem;
  }

  .bzx {
    flex: 1 1 0%;
  }

  .bzy {
    flex: 1 1 auto;
  }

  .bzz {
    flex: none;
  }

  .caa {
    flex-shrink: 0;
  }

  .cab {
    flex-shrink: 1;
  }

  .cac {
    flex-grow: 0;
  }

  .cad {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .cae {
    --tw-translate-x: 25%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .caf {
    --tw-translate-x: 4rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .cag {
    --tw-translate-x: 0.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .cah {
    --tw-translate-x: 2rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .cai {
    --tw-translate-x: 100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .caj {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .cak {
    --tw-translate-y: 1px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .cal {
    --tw-rotate: 0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .cam {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .can {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .cao {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .cap {
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .caq {
    -moz-columns: 2;
         columns: 2;
  }

  .car {
    grid-auto-flow: row dense;
  }

  .cas {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .cat {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .cau {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .cav {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .caw {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .cax {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .cay {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .caz {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .cba {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .cbb {
    flex-direction: row;
  }

  .cbc {
    flex-direction: row-reverse;
  }

  .cbd {
    flex-direction: column;
  }

  .cbe {
    flex-wrap: wrap;
  }

  .cbf {
    flex-wrap: nowrap;
  }

  .cbg {
    align-items: flex-start;
  }

  .cbh {
    align-items: flex-end;
  }

  .cbi {
    align-items: center;
  }

  .cbj {
    align-items: baseline;
  }

  .cbk {
    align-items: stretch;
  }

  .cbl {
    justify-content: flex-start;
  }

  .cbm {
    justify-content: flex-end;
  }

  .cbn {
    justify-content: center;
  }

  .cbo {
    justify-content: space-between;
  }

  .cbp {
    justify-content: space-around;
  }

  .cbq {
    gap: 0px;
  }

  .cbr {
    gap: 0.75rem;
  }

  .cbs {
    gap: 1rem;
  }

  .cbt {
    gap: 1.25rem;
  }

  .cbu {
    gap: 1.5rem;
  }

  .cbv {
    gap: 2rem;
  }

  .cbw {
    gap: 1px;
  }

  .cbx {
    -moz-column-gap: 2.5rem;
         column-gap: 2.5rem;
  }

  .cby {
    -moz-column-gap: 1rem;
         column-gap: 1rem;
  }

  .cbz {
    -moz-column-gap: 1.5rem;
         column-gap: 1.5rem;
  }

  .cca {
    -moz-column-gap: 2rem;
         column-gap: 2rem;
  }

  .ccb {
    row-gap: 0px;
  }

  .ccc {
    row-gap: 2.5rem;
  }

  .ccd {
    row-gap: 3rem;
  }

  .cce {
    row-gap: 3.5rem;
  }

  .ccf {
    row-gap: 4rem;
  }

  .ccg {
    row-gap: 5rem;
  }

  .cch {
    row-gap: 6rem;
  }

  .cci {
    row-gap: 2rem;
  }

  .ccj > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .cck > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .ccl > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .ccm > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .ccn > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .cco > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .ccp > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .ccq > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .ccr > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .ccs > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }

  .cct > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }

  .ccu > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
  }

  .ccv > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }

  .ccw > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }

  .ccx > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1;
  }

  .ccy > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .ccz > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }

  .cda > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }

  .cdb > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-divide-opacity));
  }

  .cdc > :not([hidden]) ~ :not([hidden]) {
    border-color: rgb(17 24 39 / 0.1);
  }

  .cdd {
    align-self: stretch;
  }

  .cde {
    overflow: hidden;
  }

  .cdf {
    overflow: visible;
  }

  .cdg {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .cdh {
    border-radius: 1rem;
  }

  .cdi {
    border-radius: 1.5rem;
  }

  .cdj {
    border-radius: 0.5rem;
  }

  .cdk {
    border-radius: 0.375rem;
  }

  .cdl {
    border-radius: 0px;
  }

  .cdm {
    border-radius: 0.75rem;
  }

  .cdn {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .cdo {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .cdp {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  .cdq {
    border-bottom-left-radius: 0.5rem;
  }

  .cdr {
    border-bottom-left-radius: 0px;
  }

  .cds {
    border-top-right-radius: 0.5rem;
  }

  .cdt {
    border-top-right-radius: 0px;
  }

  .cdu {
    border-width: 1px;
  }

  .cdv {
    border-width: 0px;
  }

  .cdw {
    border-left-width: 1px;
    border-right-width: 1px;
  }

  .cdx {
    border-bottom-width: 1px;
  }

  .cdy {
    border-left-width: 1px;
  }

  .cdz {
    border-right-width: 1px;
  }

  .cea {
    border-top-width: 1px;
  }

  .ceb {
    border-top-width: 0px;
  }

  .cec {
    border-style: none;
  }

  .ced {
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity));
  }

  .cee {
    border-color: rgb(17 24 39 / 0.05);
  }

  .cef {
    --tw-bg-opacity: 1;
    background-color: rgb(229 231 235 / var(--tw-bg-opacity));
  }

  .ceg {
    --tw-bg-opacity: 1;
    background-color: rgb(107 114 128 / var(--tw-bg-opacity));
  }

  .ceh {
    --tw-bg-opacity: 1;
    background-color: rgb(75 85 99 / var(--tw-bg-opacity));
  }

  .cei {
    background-color: transparent;
  }

  .cej {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }

  .cek {
    --tw-bg-opacity: 0.75;
  }

  .cel {
    padding: 0px;
  }

  .cem {
    padding: 2.5rem;
  }

  .cen {
    padding: 3rem;
  }

  .ceo {
    padding: 4rem;
  }

  .cep {
    padding: 0.75rem;
  }

  .ceq {
    padding: 1.5rem;
  }

  .cer {
    padding: 2rem;
  }

  .ces {
    padding-left: 0px;
    padding-right: 0px;
  }

  .cet {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .ceu {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .cev {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .cew {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .cex {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .cey {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .cez {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .cfa {
    padding-left: 0.875rem;
    padding-right: 0.875rem;
  }

  .cfb {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .cfc {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .cfd {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .cfe {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .cff {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .cfg {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .cfh {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .cfi {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .cfj {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .cfk {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .cfl {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .cfm {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .cfn {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .cfo {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .cfp {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .cfq {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .cfr {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .cfs {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .cft {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .cfu {
    padding-bottom: 0px;
  }

  .cfv {
    padding-bottom: 0.25rem;
  }

  .cfw {
    padding-bottom: 3.5rem;
  }

  .cfx {
    padding-bottom: 4rem;
  }

  .cfy {
    padding-bottom: 5rem;
  }

  .cfz {
    padding-bottom: 6rem;
  }

  .cga {
    padding-bottom: 8rem;
  }

  .cgb {
    padding-bottom: 1rem;
  }

  .cgc {
    padding-bottom: 10rem;
  }

  .cgd {
    padding-bottom: 1.25rem;
  }

  .cge {
    padding-bottom: 1.5rem;
  }

  .cgf {
    padding-bottom: 16rem;
  }

  .cgg {
    padding-bottom: 1.75rem;
  }

  .cgh {
    padding-bottom: 2rem;
  }

  .cgi {
    padding-left: 0px;
  }

  .cgj {
    padding-left: 4rem;
  }

  .cgk {
    padding-left: 5rem;
  }

  .cgl {
    padding-left: 0.75rem;
  }

  .cgm {
    padding-left: 1rem;
  }

  .cgn {
    padding-left: 1.25rem;
  }

  .cgo {
    padding-left: 1.5rem;
  }

  .cgp {
    padding-right: 0px;
  }

  .cgq {
    padding-right: 3rem;
  }

  .cgr {
    padding-right: 0.5rem;
  }

  .cgs {
    padding-right: 5rem;
  }

  .cgt {
    padding-right: 0.75rem;
  }

  .cgu {
    padding-right: 0.875rem;
  }

  .cgv {
    padding-right: 1rem;
  }

  .cgw {
    padding-right: 1.5rem;
  }

  .cgx {
    padding-right: 2rem;
  }

  .cgy {
    padding-right: 2.25rem;
  }

  .cgz {
    padding-top: 0px;
  }

  .cha {
    padding-top: 0.25rem;
  }

  .chb {
    padding-top: 0.375rem;
  }

  .chc {
    padding-top: 2.5rem;
  }

  .chd {
    padding-top: 3rem;
  }

  .che {
    padding-top: 3.5rem;
  }

  .chf {
    padding-top: 4rem;
  }

  .chg {
    padding-top: 5rem;
  }

  .chh {
    padding-top: 6rem;
  }

  .chi {
    padding-top: 8rem;
  }

  .chj {
    padding-top: 1rem;
  }

  .chk {
    padding-top: 10rem;
  }

  .chl {
    padding-top: 12rem;
  }

  .chm {
    padding-top: 1.25rem;
  }

  .chn {
    padding-top: 13rem;
  }

  .cho {
    padding-top: 1.5rem;
  }

  .chp {
    padding-top: 15rem;
  }

  .chq {
    padding-top: 2rem;
  }

  .chr {
    padding-top: 20rem;
  }

  .chs {
    text-align: left;
  }

  .cht {
    text-align: center;
  }

  .chu {
    text-align: right;
  }

  .chv {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .chw {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .chx {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .chy {
    font-size: 3rem;
    line-height: 1;
  }

  .chz {
    font-size: 3.75rem;
    line-height: 1;
  }

  .cia {
    font-size: 0;
  }

  .cib {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .cic {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .cid {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .cie {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .cif {
    line-height: 1.5rem;
  }

  .cig {
    line-height: 1.75rem;
  }

  .cih {
    line-height: 2rem;
  }

  .cii {
    line-height: 2.25rem;
  }

  .cij {
    line-height: 1;
  }

  .cik {
    letter-spacing: -0.025em;
  }

  .cil {
    opacity: 0;
  }

  .cim {
    opacity: 1;
  }

  .cin {
    opacity: 0.5;
  }

  .cio {
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .cip {
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .ciq {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .cir {
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .cis {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .cit {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .ciu {
    --tw-ring-color: rgb(17 24 39 / 0.1);
  }

  .civ {
    --tw-ring-color: rgb(17 24 39 / 0.05);
  }

  .ciw {
    --tw-ring-color: rgb(255 255 255 / 0.1);
  }

  .cix {
    --tw-blur: blur(64px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .ciy {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .ciz {
    transition-duration: 300ms;
  }

  .cja {
    transition-duration: 700ms;
  }

  .cjb {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }

  .cjc::before {
    content: var(--tw-content);
    flex: 1 1 0%;
  }
}
@media (min-width: 768px) {

  .cjd {
    position: absolute;
  }

  .cje {
    top: 0px;
    bottom: 0px;
  }

  .cjf {
    top: -5rem;
  }

  .cjg {
    bottom: 4rem;
  }

  .cjh {
    left: 0px;
  }

  .cji {
    left: 50rem;
  }

  .cjj {
    right: 0px;
  }

  .cjk {
    right: 1.5rem;
  }

  .cjl {
    top: 5rem;
  }

  .cjm {
    top: 0.75rem;
  }

  .cjn {
    top: 1.5rem;
  }

  .cjo {
    order: 1;
  }

  .cjp {
    order: 2;
  }

  .cjq {
    grid-column: span 2 / span 2;
  }

  .cjr {
    grid-column: span 3 / span 3;
  }

  .cjs {
    grid-column: span 5 / span 5;
  }

  .cjt {
    grid-column: span 7 / span 7;
  }

  .cju {
    grid-column: span 8 / span 8;
  }

  .cjv {
    grid-column-start: 2;
  }

  .cjw {
    grid-column-start: 3;
  }

  .cjx {
    grid-column-start: 4;
  }

  .cjy {
    grid-row: span 2 / span 2;
  }

  .cjz {
    grid-row-start: 1;
  }

  .cka {
    grid-row-start: 2;
  }

  .ckb {
    grid-row-end: 1;
  }

  .ckc {
    grid-row-end: 2;
  }

  .ckd {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .cke {
    margin-left: 0px;
    margin-right: 0px;
  }

  .ckf {
    margin-left: auto;
    margin-right: auto;
  }

  .ckg {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .ckh {
    margin-left: -4rem;
  }

  .cki {
    margin-left: -1rem;
  }

  .ckj {
    margin-right: -4rem;
  }

  .ckk {
    margin-right: -5rem;
  }

  .ckl {
    margin-top: -1px;
  }

  .ckm {
    margin-bottom: 0.5rem;
  }

  .ckn {
    margin-left: 0px;
  }

  .cko {
    margin-left: 2.5rem;
  }

  .ckp {
    margin-left: 3rem;
  }

  .ckq {
    margin-left: 5rem;
  }

  .ckr {
    margin-left: 1rem;
  }

  .cks {
    margin-left: 1.5rem;
  }

  .ckt {
    margin-left: auto;
  }

  .cku {
    margin-right: 0px;
  }

  .ckv {
    margin-top: 0px;
  }

  .ckw {
    margin-top: 0.25rem;
  }

  .ckx {
    margin-top: 2.5rem;
  }

  .cky {
    margin-top: 4rem;
  }

  .ckz {
    margin-top: 0.5rem;
  }

  .cla {
    margin-top: 6rem;
  }

  .clb {
    margin-top: 8rem;
  }

  .clc {
    margin-top: 1.25rem;
  }

  .cld {
    margin-top: 2rem;
  }

  .cle {
    display: block;
  }

  .clf {
    display: inline-block;
  }

  .clg {
    display: inline;
  }

  .clh {
    display: flex;
  }

  .cli {
    display: inline-flex;
  }

  .clj {
    display: table-cell;
  }

  .clk {
    display: grid;
  }

  .cll {
    display: none;
  }

  .clm {
    height: 10rem;
  }

  .cln {
    height: 14rem;
  }

  .clo {
    height: 18rem;
  }

  .clp {
    height: 24rem;
  }

  .clq {
    height: 100%;
  }

  .clr {
    height: 100vh;
  }

  .cls {
    width: 50%;
  }

  .clt {
    width: 33.333333%;
  }

  .clu {
    width: 66.666667%;
  }

  .clv {
    width: 14rem;
  }

  .clw {
    width: 18rem;
  }

  .clx {
    width: 20rem;
  }

  .cly {
    width: 2.25rem;
  }

  .clz {
    width: auto;
  }

  .cma {
    min-width: 0px;
  }

  .cmb {
    max-width: 42rem;
  }

  .cmc {
    max-width: 48rem;
  }

  .cmd {
    max-width: 56rem;
  }

  .cme {
    max-width: 100%;
  }

  .cmf {
    max-width: none;
  }

  .cmg {
    flex: 1 1 0%;
  }

  .cmh {
    flex-shrink: 0;
  }

  .cmi {
    flex-grow: 1;
  }

  .cmj {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .cmk {
    --tw-translate-y: -6rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .cml {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .cmm {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .cmn {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .cmo {
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .cmp {
    grid-auto-flow: column;
  }

  .cmq {
    grid-auto-rows: min-content;
  }

  .cmr {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .cms {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .cmt {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .cmu {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .cmv {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .cmw {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .cmx {
    flex-direction: row;
  }

  .cmy {
    flex-direction: column;
  }

  .cmz {
    align-items: flex-start;
  }

  .cna {
    align-items: center;
  }

  .cnb {
    align-items: baseline;
  }

  .cnc {
    align-items: stretch;
  }

  .cnd {
    justify-content: flex-start;
  }

  .cne {
    justify-content: flex-end;
  }

  .cnf {
    justify-content: center;
  }

  .cng {
    justify-content: space-between;
  }

  .cnh {
    gap: 1rem;
  }

  .cni {
    gap: 2rem;
  }

  .cnj {
    -moz-column-gap: 2.75rem;
         column-gap: 2.75rem;
  }

  .cnk {
    -moz-column-gap: 1.5rem;
         column-gap: 1.5rem;
  }

  .cnl {
    -moz-column-gap: 2rem;
         column-gap: 2rem;
  }

  .cnm {
    row-gap: 0px;
  }

  .cnn {
    row-gap: 3rem;
  }

  .cno {
    row-gap: 4rem;
  }

  .cnp > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .cnq > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .cnr > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .cns > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .cnt > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .cnu > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .cnv > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .cnw > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .cnx > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }

  .cny > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-divide-opacity));
  }

  .cnz {
    border-radius: 1.5rem;
  }

  .coa {
    border-left-width: 1px;
  }

  .cob {
    border-left-width: 0px;
  }

  .coc {
    border-right-width: 1px;
  }

  .cod {
    border-top-width: 0px;
  }

  .coe {
    border-top-width: 4px;
  }

  .cof {
    --tw-border-opacity: 1;
    border-color: rgb(49 46 129 / var(--tw-border-opacity));
  }

  .cog {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }

  .coh {
    -o-object-position: center;
       object-position: center;
  }

  .coi {
    padding: 5rem;
  }

  .coj {
    padding: 1.5rem;
  }

  .cok {
    padding-left: 0px;
    padding-right: 0px;
  }

  .col {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .com {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .con {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .coo {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .cop {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .coq {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .cor {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .cos {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .cot {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .cou {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .cov {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .cow {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .cox {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .coy {
    padding-bottom: 0px;
  }

  .coz {
    padding-bottom: 1rem;
  }

  .cpa {
    padding-left: 0px;
  }

  .cpb {
    padding-left: 2.5rem;
  }

  .cpc {
    padding-left: 3.5rem;
  }

  .cpd {
    padding-left: 4rem;
  }

  .cpe {
    padding-left: 1rem;
  }

  .cpf {
    padding-right: 0px;
  }

  .cpg {
    padding-right: 2.5rem;
  }

  .cph {
    padding-right: 3.5rem;
  }

  .cpi {
    padding-right: 1.5rem;
  }

  .cpj {
    padding-top: 0px;
  }

  .cpk {
    padding-top: 6rem;
  }

  .cpl {
    padding-top: 1rem;
  }

  .cpm {
    text-align: left;
  }

  .cpn {
    text-align: center;
  }

  .cpo {
    text-align: right;
  }

  .cpp {
    vertical-align: middle;
  }

  .cpq {
    font-size: 3rem;
    line-height: 1;
  }

  .cpr {
    font-size: 3.75rem;
    line-height: 1;
  }

  .cps {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .cpt {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .cpu {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .cpv {
    font-weight: 600;
  }

  .cpw {
    line-height: 1.5rem;
  }

  .cpx {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity));
  }

  .cpy {
    --tw-blur: blur(64px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .cpz {
    -webkit-clip-path: inset(0 round 1.5rem);
            clip-path: inset(0 round 1.5rem);
  }

  .cqa {
    overflow-anchor: none;
  }

  .cqb:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity));
  }
}
@media (min-width: 1024px) {

  .cqc {
    position: static;
  }

  .cqd {
    position: fixed;
  }

  .cqe {
    position: absolute;
  }

  .cqf {
    position: relative;
  }

  .cqg {
    position: sticky;
  }

  .cqh {
    inset: 0px;
  }

  .cqi {
    left: auto;
    right: auto;
  }

  .cqj {
    left: 1px;
    right: 1px;
  }

  .cqk {
    top: 0px;
    bottom: 0px;
  }

  .cql {
    top: 0.5rem;
    bottom: 0.5rem;
  }

  .cqm {
    bottom: -8rem;
  }

  .cqn {
    right: -2rem;
  }

  .cqo {
    top: -3rem;
  }

  .cqp {
    top: -12rem;
  }

  .cqq {
    bottom: 0px;
  }

  .cqr {
    bottom: -12rem;
  }

  .cqs {
    bottom: auto;
  }

  .cqt {
    left: 0px;
  }

  .cqu {
    left: 50%;
  }

  .cqv {
    left: 9rem;
  }

  .cqw {
    left: 12rem;
  }

  .cqx {
    left: 2rem;
  }

  .cqy {
    left: 20rem;
  }

  .cqz {
    left: max(-14rem,calc(100% - 59rem));
  }

  .cra {
    left: auto;
  }

  .crb {
    right: 0px;
  }

  .crc {
    right: 0.5rem;
  }

  .crd {
    right: 18rem;
  }

  .cre {
    right: 2rem;
  }

  .crf {
    right: 100%;
  }

  .crg {
    top: 0px;
  }

  .crh {
    top: 50%;
  }

  .cri {
    top: 3rem;
  }

  .crj {
    top: 4rem;
  }

  .crk {
    top: 1rem;
  }

  .crl {
    top: 2rem;
  }

  .crm {
    top: calc(50% - 30rem);
  }

  .crn {
    top: calc(50% - 7rem);
  }

  .cro {
    top: auto;
  }

  .crp {
    top: 100%;
  }

  .crq {
    z-index: 10;
  }

  .crr {
    z-index: 50;
  }

  .crs {
    order: -9999;
  }

  .crt {
    order: 9999;
  }

  .cru {
    grid-column: span 1 / span 1;
  }

  .crv {
    grid-column: span 10 / span 10;
  }

  .crw {
    grid-column: span 2 / span 2;
  }

  .crx {
    grid-column: span 3 / span 3;
  }

  .cry {
    grid-column: span 4 / span 4;
  }

  .crz {
    grid-column: span 5 / span 5;
  }

  .csa {
    grid-column: span 6 / span 6;
  }

  .csb {
    grid-column: span 7 / span 7;
  }

  .csc {
    grid-column: span 8 / span 8;
  }

  .csd {
    grid-column: span 9 / span 9;
  }

  .cse {
    grid-column-start: 1;
  }

  .csf {
    grid-column-start: 2;
  }

  .csg {
    grid-column-start: 3;
  }

  .csh {
    grid-column-start: 4;
  }

  .csi {
    grid-column-start: 5;
  }

  .csj {
    grid-column-start: 6;
  }

  .csk {
    grid-column-start: 8;
  }

  .csl {
    grid-column-start: 9;
  }

  .csm {
    grid-column-end: 1;
  }

  .csn {
    grid-column-end: 13;
  }

  .cso {
    grid-column-end: 2;
  }

  .csp {
    grid-column-end: 3;
  }

  .csq {
    grid-column-end: 6;
  }

  .csr {
    grid-column-end: 8;
  }

  .css {
    grid-row: span 2 / span 2;
  }

  .cst {
    grid-row: span 3 / span 3;
  }

  .csu {
    grid-row: span 4 / span 4;
  }

  .csv {
    grid-row-start: 1;
  }

  .csw {
    grid-row-start: 2;
  }

  .csx {
    grid-row-start: 3;
  }

  .csy {
    grid-row-end: 1;
  }

  .csz {
    grid-row-end: 2;
  }

  .cta {
    grid-row-end: 3;
  }

  .ctb {
    grid-row-end: 4;
  }

  .ctc {
    margin: -1rem;
  }

  .ctd {
    margin: 0px;
  }

  .cte {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .ctf {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .ctg {
    margin-left: 0px;
    margin-right: 0px;
  }

  .cth {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .cti {
    margin-left: auto;
    margin-right: auto;
  }

  .ctj {
    margin-bottom: -3.5rem;
  }

  .ctk {
    margin-bottom: -20rem;
  }

  .ctl {
    margin-left: -0px;
  }

  .ctm {
    margin-left: -1rem;
  }

  .ctn {
    margin-left: -2rem;
  }

  .cto {
    margin-right: -0.25rem;
  }

  .ctp {
    margin-right: -0.375rem;
  }

  .ctq {
    margin-right: -9rem;
  }

  .ctr {
    margin-right: -1.5rem;
  }

  .cts {
    margin-right: -2rem;
  }

  .ctt {
    margin-right: -24rem;
  }

  .ctu {
    margin-top: -3rem;
  }

  .ctv {
    margin-top: -4rem;
  }

  .ctw {
    margin-bottom: 0px;
  }

  .ctx {
    margin-left: 0px;
  }

  .cty {
    margin-left: 2.5rem;
  }

  .ctz {
    margin-left: 3rem;
  }

  .cua {
    margin-left: 5rem;
  }

  .cub {
    margin-left: 6rem;
  }

  .cuc {
    margin-left: 9rem;
  }

  .cud {
    margin-left: 1rem;
  }

  .cue {
    margin-left: 1.5rem;
  }

  .cuf {
    margin-left: 2rem;
  }

  .cug {
    margin-left: auto;
  }

  .cuh {
    margin-right: 0px;
  }

  .cui {
    margin-top: 0px;
  }

  .cuj {
    margin-top: 2.5rem;
  }

  .cuk {
    margin-top: 4rem;
  }

  .cul {
    margin-top: 0.5rem;
  }

  .cum {
    margin-top: 5rem;
  }

  .cun {
    margin-top: 6rem;
  }

  .cuo {
    margin-top: 7rem;
  }

  .cup {
    margin-top: 0.75rem;
  }

  .cuq {
    margin-top: 8rem;
  }

  .cur {
    margin-top: 1rem;
  }

  .cus {
    margin-top: 1.25rem;
  }

  .cut {
    margin-top: 1.5rem;
  }

  .cuu {
    margin-top: 2rem;
  }

  .cuv {
    margin-top: 2.25rem;
  }

  .cuw {
    display: block;
  }

  .cux {
    display: inline;
  }

  .cuy {
    display: flex;
  }

  .cuz {
    display: table-cell;
  }

  .cva {
    display: grid;
  }

  .cvb {
    display: contents;
  }

  .cvc {
    display: none;
  }

  .cvd {
    aspect-ratio: 3/2;
  }

  .cve {
    aspect-ratio: auto;
  }

  .cvf {
    aspect-ratio: 1 / 1;
  }

  .cvg {
    height: 0px;
  }

  .cvh {
    height: 0.25rem;
  }

  .cvi {
    height: 66.666667%;
  }

  .cvj {
    height: 7rem;
  }

  .cvk {
    height: 12rem;
  }

  .cvl {
    height: 13rem;
  }

  .cvm {
    height: 1.5rem;
  }

  .cvn {
    height: 16rem;
  }

  .cvo {
    height: 18rem;
  }

  .cvp {
    height: 20rem;
  }

  .cvq {
    height: 24rem;
  }

  .cvr {
    height: 1024px;
  }

  .cvs {
    height: 1038px;
  }

  .cvt {
    height: 1147px;
  }

  .cvu {
    height: 1160px;
  }

  .cvv {
    height: 34.5rem;
  }

  .cvw {
    height: 816px;
  }

  .cvx {
    height: 985px;
  }

  .cvy {
    height: auto;
  }

  .cvz {
    height: 100%;
  }

  .cwa {
    min-height: 1100px;
  }

  .cwb {
    min-height: 192px;
  }

  .cwc {
    min-height: 464px;
  }

  .cwd {
    min-height: 583px;
  }

  .cwe {
    min-height: 592px;
  }

  .cwf {
    min-height: 700px;
  }

  .cwg {
    min-height: 768px;
  }

  .cwh {
    min-height: 851px;
  }

  .cwi {
    min-height: 100%;
  }

  .cwj {
    width: 0px;
  }

  .cwk {
    width: 8.333333%;
  }

  .cwl {
    width: 50%;
  }

  .cwm {
    width: 33.333333%;
  }

  .cwn {
    width: 16.666667%;
  }

  .cwo {
    width: 5rem;
  }

  .cwp {
    width: 33.333333%;
  }

  .cwq {
    width: 12rem;
  }

  .cwr {
    width: 13rem;
  }

  .cws {
    width: 16rem;
  }

  .cwt {
    width: 18rem;
  }

  .cwu {
    width: 20rem;
  }

  .cwv {
    width: 24rem;
  }

  .cww {
    width: 37rem;
  }

  .cwx {
    width: 64rem;
  }

  .cwy {
    width: auto;
  }

  .cwz {
    width: 100%;
  }

  .cxa {
    width: 1px;
  }

  .cxb {
    width: 100vw;
  }

  .cxc {
    min-width: 0px;
  }

  .cxd {
    min-width: 100%;
  }

  .cxe {
    max-width: 42rem;
  }

  .cxf {
    max-width: 48rem;
  }

  .cxg {
    max-width: 56rem;
  }

  .cxh {
    max-width: 64rem;
  }

  .cxi {
    max-width: 72rem;
  }

  .cxj {
    max-width: 80rem;
  }

  .cxk {
    max-width: 32rem;
  }

  .cxl {
    max-width: 28rem;
  }

  .cxm {
    max-width: none;
  }

  .cxn {
    max-width: 24rem;
  }

  .cxo {
    max-width: 36rem;
  }

  .cxp {
    max-width: 20rem;
  }

  .cxq {
    flex: 1 1 0%;
  }

  .cxr {
    flex: 1 1 auto;
  }

  .cxs {
    flex: none;
  }

  .cxt {
    flex-shrink: 0;
  }

  .cxu {
    flex-shrink: 1;
  }

  .cxv {
    flex-shrink: 0;
  }

  .cxw {
    flex-grow: 1;
  }

  .cxx {
    flex-grow: 0;
  }

  .cxy {
    flex-grow: 1;
  }

  .cxz {
    flex-grow: 0;
  }

  .cya {
    transform-origin: center;
  }

  .cyb {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .cyc {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .cyd {
    --tw-translate-y: -18rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .cye {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .cyf {
    --tw-translate-x: 50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .cyg {
    --tw-translate-x: 2rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .cyh {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .cyi {
    --tw-translate-y: 5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .cyj {
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .cyk {
    -moz-columns: 3;
         columns: 3;
  }

  .cyl {
    grid-auto-flow: row dense;
  }

  .cym {
    grid-auto-flow: column dense;
  }

  .cyn {
    grid-auto-rows: min-content;
  }

  .cyo {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .cyp {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .cyq {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .cyr {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .cys {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .cyt {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .cyu {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .cyv {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .cyw {
    grid-template-columns: max(50%,36rem) 1fr;
  }

  .cyx {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .cyy {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .cyz {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .cza {
    grid-template-rows: auto auto 1fr;
  }

  .czb {
    flex-direction: row;
  }

  .czc {
    flex-direction: row-reverse;
  }

  .czd {
    flex-direction: column;
  }

  .cze {
    align-items: flex-start;
  }

  .czf {
    align-items: flex-end;
  }

  .czg {
    align-items: center;
  }

  .czh {
    align-items: stretch;
  }

  .czi {
    justify-content: flex-start;
  }

  .czj {
    justify-content: flex-end;
  }

  .czk {
    justify-content: center;
  }

  .czl {
    justify-content: space-between;
  }

  .czm {
    gap: 6rem;
  }

  .czn {
    gap: 1rem;
  }

  .czo {
    gap: 1.25rem;
  }

  .czp {
    gap: 1.5rem;
  }

  .czq {
    gap: 2rem;
  }

  .czr {
    gap: 1px;
  }

  .czs {
    -moz-column-gap: 2.5rem;
         column-gap: 2.5rem;
  }

  .czt {
    -moz-column-gap: 3rem;
         column-gap: 3rem;
  }

  .czu {
    -moz-column-gap: 3.5rem;
         column-gap: 3.5rem;
  }

  .czv {
    -moz-column-gap: 4rem;
         column-gap: 4rem;
  }

  .czw {
    -moz-column-gap: 5rem;
         column-gap: 5rem;
  }

  .czx {
    -moz-column-gap: 1.25rem;
         column-gap: 1.25rem;
  }

  .czy {
    -moz-column-gap: 1.5rem;
         column-gap: 1.5rem;
  }

  .czz {
    -moz-column-gap: 2rem;
         column-gap: 2rem;
  }

  .daa {
    row-gap: 0px;
  }

  .dab {
    row-gap: 2.5rem;
  }

  .dac {
    row-gap: 3rem;
  }

  .dad {
    row-gap: 4rem;
  }

  .dae {
    row-gap: 7rem;
  }

  .daf {
    row-gap: 11rem;
  }

  .dag {
    row-gap: 1.5rem;
  }

  .dah {
    row-gap: 2rem;
  }

  .dai > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .daj > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .dak > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .dal > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .dam > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .dan > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .dao > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  }

  .dap > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }

  .daq > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }

  .dar > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .das > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }

  .dat > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }

  .dau > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-divide-opacity));
  }

  .dav > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-divide-opacity));
  }

  .daw {
    align-self: flex-start;
  }

  .dax {
    align-self: flex-end;
  }

  .day {
    align-self: center;
  }

  .daz {
    align-self: stretch;
  }

  .dba {
    overflow: hidden;
  }

  .dbb {
    overflow: visible;
  }

  .dbc {
    overflow-y: auto;
  }

  .dbd {
    overflow-y: visible;
  }

  .dbe {
    border-radius: 1rem;
  }

  .dbf {
    border-radius: 1.5rem;
  }

  .dbg {
    border-radius: 0.5rem;
  }

  .dbh {
    border-radius: 0.375rem;
  }

  .dbi {
    border-radius: 0px;
  }

  .dbj {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .dbk {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .dbl {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .dbm {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .dbn {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .dbo {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .dbp {
    border-bottom-left-radius: 1.5rem;
  }

  .dbq {
    border-bottom-left-radius: 0px;
  }

  .dbr {
    border-bottom-right-radius: 0px;
  }

  .dbs {
    border-top-left-radius: 0.5rem;
  }

  .dbt {
    border-top-right-radius: 1.5rem;
  }

  .dbu {
    border-top-right-radius: 0px;
  }

  .dbv {
    border-width: 0px;
  }

  .dbw {
    border-bottom-width: 1px;
  }

  .dbx {
    border-left-width: 1px;
  }

  .dby {
    border-right-width: 1px;
  }

  .dbz {
    border-top-width: 1px;
  }

  .dca {
    border-top-width: 0px;
  }

  .dcb {
    border-style: none;
  }

  .dcc {
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity));
  }

  .dcd {
    border-color: rgb(17 24 39 / 0.1);
  }

  .dce {
    --tw-border-opacity: 1;
    border-color: rgb(129 140 248 / var(--tw-border-opacity));
  }

  .dcf {
    --tw-border-opacity: 1;
    border-color: rgb(7 89 133 / var(--tw-border-opacity));
  }

  .dcg {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity));
  }

  .dch {
    border-color: rgb(255 255 255 / 0.1);
  }

  .dci {
    border-color: rgb(255 255 255 / 0.05);
  }

  .dcj {
    border-top-color: rgb(17 24 39 / 0.05);
  }

  .dck {
    --tw-border-opacity: 0.2;
  }

  .dcl {
    --tw-border-opacity: 0.25;
  }

  .dcm {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  }

  .dcn {
    --tw-bg-opacity: 1;
    background-color: rgb(229 231 235 / var(--tw-bg-opacity));
  }

  .dco {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity));
  }

  .dcp {
    background-color: rgb(31 41 55 / 0.8);
  }

  .dcq {
    --tw-bg-opacity: 1;
    background-color: rgb(17 24 39 / var(--tw-bg-opacity));
  }

  .dcr {
    background-color: rgb(17 24 39 / 0.1);
  }

  .dcs {
    background-color: transparent;
  }

  .dct {
    background-image: none;
  }

  .dcu {
    padding: 0px;
  }

  .dcv {
    padding: 0.5rem;
  }

  .dcw {
    padding: 5rem;
  }

  .dcx {
    padding: 1rem;
  }

  .dcy {
    padding: 1.5rem;
  }

  .dcz {
    padding: 2rem;
  }

  .dda {
    padding-left: 0px;
    padding-right: 0px;
  }

  .ddb {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .ddc {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .ddd {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .dde {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .ddf {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .ddg {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .ddh {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .ddi {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .ddj {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .ddk {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .ddl {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .ddm {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .ddn {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .ddo {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .ddp {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .ddq {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .ddr {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .dds {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }

  .ddt {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .ddu {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .ddv {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .ddw {
    padding-bottom: 0px;
  }

  .ddx {
    padding-bottom: 2.5rem;
  }

  .ddy {
    padding-bottom: 3.5rem;
  }

  .ddz {
    padding-bottom: 4rem;
  }

  .dea {
    padding-bottom: 5rem;
  }

  .deb {
    padding-bottom: 6rem;
  }

  .dec {
    padding-bottom: 7rem;
  }

  .ded {
    padding-bottom: 8rem;
  }

  .dee {
    padding-bottom: 1rem;
  }

  .def {
    padding-bottom: 10rem;
  }

  .deg {
    padding-bottom: 12rem;
  }

  .deh {
    padding-bottom: 14rem;
  }

  .dei {
    padding-bottom: 2rem;
  }

  .dej {
    padding-left: 0px;
  }

  .dek {
    padding-left: 2.5rem;
  }

  .del {
    padding-left: 3rem;
  }

  .dem {
    padding-left: 4rem;
  }

  .den {
    padding-left: 0.5rem;
  }

  .deo {
    padding-left: 5rem;
  }

  .dep {
    padding-left: 6rem;
  }

  .deq {
    padding-left: 1rem;
  }

  .der {
    padding-left: 16rem;
  }

  .des {
    padding-left: 18rem;
  }

  .det {
    padding-left: 2rem;
  }

  .deu {
    padding-left: 2.25rem;
  }

  .dev {
    padding-right: 0px;
  }

  .dew {
    padding-right: 0.125rem;
  }

  .dex {
    padding-right: 4rem;
  }

  .dey {
    padding-right: 5rem;
  }

  .dez {
    padding-right: 1rem;
  }

  .dfa {
    padding-right: 2rem;
  }

  .dfb {
    padding-right: 24rem;
  }

  .dfc {
    padding-top: 0px;
  }

  .dfd {
    padding-top: 2.5rem;
  }

  .dfe {
    padding-top: 3rem;
  }

  .dff {
    padding-top: 3.5rem;
  }

  .dfg {
    padding-top: 4rem;
  }

  .dfh {
    padding-top: 0.5rem;
  }

  .dfi {
    padding-top: 5rem;
  }

  .dfj {
    padding-top: 6rem;
  }

  .dfk {
    padding-top: 8rem;
  }

  .dfl {
    padding-top: 9rem;
  }

  .dfm {
    padding-top: 1rem;
  }

  .dfn {
    padding-top: 10rem;
  }

  .dfo {
    padding-top: 12rem;
  }

  .dfp {
    padding-top: 1.25rem;
  }

  .dfq {
    padding-top: 1.5rem;
  }

  .dfr {
    padding-top: 2rem;
  }

  .dfs {
    padding-top: 20rem;
  }

  .dft {
    text-align: left;
  }

  .dfu {
    text-align: center;
  }

  .dfv {
    font-size: 3rem;
    line-height: 1;
  }

  .dfw {
    font-size: 3.75rem;
    line-height: 1;
  }

  .dfx {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .dfy {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .dfz {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .dga {
    font-weight: 600;
  }

  .dgb {
    line-height: 1.5rem;
  }

  .dgc {
    --tw-text-opacity: 1;
    color: rgb(17 24 39 / var(--tw-text-opacity));
  }

  .dgd {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .dge {
    opacity: 1;
  }

  .dgf {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dgg {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .dgh {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .dgi {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity));
  }

  .dgj {
    --tw-ring-color: rgb(255 255 255 / 0.1);
  }

  .dgk {
    --tw-ring-opacity: 0.05;
  }

  .dgl:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity));
  }
}
@media (min-width: 1280px) {

  .dgm {
    position: static;
  }

  .dgn {
    position: fixed;
  }

  .dgo {
    position: absolute;
  }

  .dgp {
    position: relative;
  }

  .dgq {
    inset: 0px;
  }

  .dgr {
    top: 0px;
    bottom: 0px;
  }

  .dgs {
    left: -2.5rem;
  }

  .dgt {
    top: -1.5rem;
  }

  .dgu {
    bottom: auto;
  }

  .dgv {
    left: 0px;
  }

  .dgw {
    left: 50%;
  }

  .dgx {
    left: calc(50% - 24rem);
  }

  .dgy {
    right: 50%;
  }

  .dgz {
    right: auto;
  }

  .dha {
    top: 0px;
  }

  .dhb {
    top: 0.75rem;
  }

  .dhc {
    top: auto;
  }

  .dhd {
    z-index: 50;
  }

  .dhe {
    order: -9999;
  }

  .dhf {
    order: 9999;
  }

  .dhg {
    order: 0;
  }

  .dhh {
    grid-column: auto;
  }

  .dhi {
    grid-column: span 1 / span 1;
  }

  .dhj {
    grid-column: span 2 / span 2;
  }

  .dhk {
    grid-column: span 3 / span 3;
  }

  .dhl {
    grid-column: span 4 / span 4;
  }

  .dhm {
    grid-column: span 6 / span 6;
  }

  .dhn {
    grid-column: span 8 / span 8;
  }

  .dho {
    grid-column: span 9 / span 9;
  }

  .dhp {
    grid-column-start: 1;
  }

  .dhq {
    grid-column-start: 2;
  }

  .dhr {
    grid-column-start: 4;
  }

  .dhs {
    grid-column-start: 5;
  }

  .dht {
    grid-column-start: 9;
  }

  .dhu {
    grid-column-end: 1;
  }

  .dhv {
    grid-row: span 2 / span 2;
  }

  .dhw {
    grid-row-start: 1;
  }

  .dhx {
    grid-row-end: 1;
  }

  .dhy {
    grid-row-end: 2;
  }

  .dhz {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .dia {
    margin-left: 0px;
    margin-right: 0px;
  }

  .dib {
    margin-left: auto;
    margin-right: auto;
  }

  .dic {
    margin-bottom: -2rem;
  }

  .did {
    margin-left: -20rem;
  }

  .die {
    margin-top: -2rem;
  }

  .dif {
    margin-left: 0px;
  }

  .dig {
    margin-left: 0.75rem;
  }

  .dih {
    margin-left: 0.875rem;
  }

  .dii {
    margin-left: 8rem;
  }

  .dij {
    margin-left: 1rem;
  }

  .dik {
    margin-left: 12rem;
  }

  .dil {
    margin-left: 14rem;
  }

  .dim {
    margin-left: 2rem;
  }

  .din {
    margin-right: 0px;
  }

  .dio {
    margin-right: 4rem;
  }

  .dip {
    margin-right: calc(50% - 12rem);
  }

  .diq {
    margin-top: 0px;
  }

  .dir {
    margin-top: 2.5rem;
  }

  .dis {
    margin-top: 4rem;
  }

  .dit {
    margin-top: 0.75rem;
  }

  .diu {
    margin-top: 9rem;
  }

  .div {
    display: block;
  }

  .diw {
    display: inline;
  }

  .dix {
    display: flex;
  }

  .diy {
    display: grid;
  }

  .diz {
    display: contents;
  }

  .dja {
    display: none;
  }

  .djb {
    aspect-ratio: auto;
  }

  .djc {
    height: 20rem;
  }

  .djd {
    height: 608px;
  }

  .dje {
    height: 100%;
  }

  .djf {
    width: 0px;
  }

  .djg {
    width: 50%;
  }

  .djh {
    width: 8rem;
  }

  .dji {
    width: 16rem;
  }

  .djj {
    width: 18rem;
  }

  .djk {
    width: 20rem;
  }

  .djl {
    width: 24rem;
  }

  .djm {
    width: auto;
  }

  .djn {
    width: 100%;
  }

  .djo {
    max-width: 42rem;
  }

  .djp {
    max-width: 56rem;
  }

  .djq {
    max-width: 64rem;
  }

  .djr {
    max-width: 72rem;
  }

  .djs {
    max-width: 80rem;
  }

  .djt {
    max-width: none;
  }

  .dju {
    flex: 1 1 0%;
  }

  .djv {
    flex: 1 1 auto;
  }

  .djw {
    flex: none;
  }

  .djx {
    flex-shrink: 0;
  }

  .djy {
    flex-shrink: 0;
  }

  .djz {
    transform-origin: center;
  }

  .dka {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .dkb {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .dkc {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .dkd {
    transform: none;
  }

  .dke {
    grid-auto-flow: column;
  }

  .dkf {
    grid-auto-flow: column dense;
  }

  .dkg {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .dkh {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .dki {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .dkj {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .dkk {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .dkl {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .dkm {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .dkn {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .dko {
    flex-direction: row;
  }

  .dkp {
    flex-direction: column;
  }

  .dkq {
    align-items: flex-start;
  }

  .dkr {
    align-items: center;
  }

  .dks {
    align-items: stretch;
  }

  .dkt {
    justify-content: flex-end;
  }

  .dku {
    justify-content: space-between;
  }

  .dkv {
    gap: 2rem;
  }

  .dkw {
    -moz-column-gap: 4rem;
         column-gap: 4rem;
  }

  .dkx {
    -moz-column-gap: 5rem;
         column-gap: 5rem;
  }

  .dky {
    -moz-column-gap: 6rem;
         column-gap: 6rem;
  }

  .dkz {
    -moz-column-gap: 12rem;
         column-gap: 12rem;
  }

  .dla {
    -moz-column-gap: 2rem;
         column-gap: 2rem;
  }

  .dlb > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .dlc > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .dld > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .dle > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }

  .dlf > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }

  .dlg > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }

  .dlh {
    align-self: center;
  }

  .dli {
    overflow: hidden;
  }

  .dlj {
    overflow: visible;
  }

  .dlk {
    overflow-y: auto;
  }

  .dll {
    border-radius: 1.5rem;
  }

  .dlm {
    border-bottom-width: 1px;
  }

  .dln {
    border-bottom-width: 0px;
  }

  .dlo {
    border-left-width: 1px;
  }

  .dlp {
    border-right-width: 1px;
  }

  .dlq {
    border-top-width: 0px;
  }

  .dlr {
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity));
  }

  .dls {
    --tw-border-opacity: 1;
    border-color: rgb(156 163 175 / var(--tw-border-opacity));
  }

  .dlt {
    --tw-border-opacity: 0.5;
  }

  .dlu {
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
  }

  .dlv {
    padding: 2.5rem;
  }

  .dlw {
    padding: 3rem;
  }

  .dlx {
    padding-left: 0px;
    padding-right: 0px;
  }

  .dly {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .dlz {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .dma {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .dmb {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .dmc {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .dmd {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .dme {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .dmf {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .dmg {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .dmh {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .dmi {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .dmj {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .dmk {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }

  .dml {
    padding-bottom: 0px;
  }

  .dmm {
    padding-bottom: 2.5rem;
  }

  .dmn {
    padding-bottom: 5rem;
  }

  .dmo {
    padding-bottom: 6rem;
  }

  .dmp {
    padding-bottom: 8rem;
  }

  .dmq {
    padding-bottom: 1.5rem;
  }

  .dmr {
    padding-left: 0px;
  }

  .dms {
    padding-left: 3rem;
  }

  .dmt {
    padding-left: 5rem;
  }

  .dmu {
    padding-left: 0.75rem;
  }

  .dmv {
    padding-left: 0.875rem;
  }

  .dmw {
    padding-left: 8rem;
  }

  .dmx {
    padding-left: 1.5rem;
  }

  .dmy {
    padding-left: 18rem;
  }

  .dmz {
    padding-left: 2rem;
  }

  .dna {
    padding-left: 24rem;
  }

  .dnb {
    padding-right: 0px;
  }

  .dnc {
    padding-right: 3rem;
  }

  .dnd {
    padding-right: 4rem;
  }

  .dne {
    padding-right: 5rem;
  }

  .dnf {
    padding-right: 1.5rem;
  }

  .dng {
    padding-right: 2rem;
  }

  .dnh {
    padding-right: 24rem;
  }

  .dni {
    padding-top: 3.5rem;
  }

  .dnj {
    padding-top: 4rem;
  }

  .dnk {
    padding-top: 1.5rem;
  }

  .dnl {
    padding-top: 2rem;
  }

  .dnm {
    padding-top: 20rem;
  }

  .dnn {
    text-align: center;
  }

  .dno {
    font-size: 3.75rem;
    line-height: 1;
  }

  .dnp {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .dnq {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .dnr {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}
@media (min-width: 1536px) {

  .dns {
    margin-top: 1.25rem;
  }

  .dnt {
    display: block;
  }

  .dnu {
    display: none;
  }

  .dnv {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
.dnw * {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
