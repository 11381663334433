/* Pour le mode sombre normal */
.dark\:shadow-light {
    box-shadow: 0 4px 6px 0 rgba(255, 255, 255, 0.1), 0 1px 3px 0 rgba(255, 255, 255, 0.08);
  }
  
  /* Pour le hover en mode sombre */
  .dark\:hover\:shadow-light-hover:hover {
    box-shadow: 0 10px 15px 0 rgba(255, 255, 255, 0.1), 0 4px 6px 0 rgba(255, 255, 255, 0.1);
  }
  
  /* Pour le hover en mode sombre */
/* Pour le mode clair normal */
.light\:shadow-light {
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.08);
}

/* Pour le hover en mode clair */
.light\:hover\:shadow-light-hover:hover {
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1), 0 4px 6px 0 rgba(0, 0, 0, 0.1);
}
.light\:hover\:shadow-professional-plan-hover:hover {
  -webkit-box-shadow: 0px 0px 15px -4px #3f83f8; 
  box-shadow: 0px 0px 15px -4px #3f83f8;}
.light\:hover\:shadow-ultimate-plan-hover:hover {
  -webkit-box-shadow: 0px 0px 15px -4px #faca15;
  box-shadow: 0px 0px 15px -4px #faca15;
}

.text-ssm{
  font-size: 10px;
}