@media (max-width: 640px) {
    ul {
      -webkit-overflow-scrolling: touch;
    }
  }

  /* Cacher la barre de défilement pour les éléments qui débordent */
.overflow-hidden-element {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }
  
  .overflow-hidden-element::-webkit-scrollbar {
    width: 0; /* Chrome, Safari et Opera */
    height: 0; /* Hauteur pour les scrollbars horizontales */
  }
  

  /* Cacher la barre de défilement pour les éléments qui débordent */
.overflow-hidden-element {
    -ms-overflow-style: none;
  }
  