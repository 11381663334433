/* .carousel {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    position: relative;
  }
  
  .carousel-wrapper {
    display: flex;
    transition: all 0.5s;
  }
  
  .carousel-item {
    flex: 0 0 auto;
    height: auto;
    width: 25vh;  /* ou toute autre taille */
    /* padding: 10px; */
    /* box-sizing: border-box; */
    /* cursor: pointer; */
  /* }  */
  
  /* Ajoutez plus de styles si nécessaire */
  .slider-invoice-theme{
    
    /* display: flex; */
    /* flex-direction: row; */
    /* max-width: 50%; */
  }

  .slick-slider {
    display: block;
    max-width: 100%;
    min-width: 100%;
    overflow-x: hidden;
  }
  body{
    overflow-x: hidden;
  }
  
  .slick-track {
    display: flex;
  }
  
  .slick-slide {
    height: auto;
    display: inline-block;
    margin-right: 10px;
  }
  