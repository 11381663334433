.chatbox-btn .wrap-icon {
    margin: 15px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    position: relative;
    margin-bottom: 30px;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .chatbox-btn .wrap-icon.icon-1 {
    background: linear-gradient(-45deg, #3b87bd, #2d71a2);

  }
  
  .chatbox-btn .wrap-icon i {
    font-size: 20px;
    line-height: 0;
    color: #fff;
  }


#input-chatbox{
  border-radius: 15px!important;
  -webkit-border-radius: 15px!important;
  -moz-border-radius: 15px!important;
  -ms-border-radius: 15px!important;
  -o-border-radius: 15px!important;
  font-size: 15px;
}

#chatbox-container{
  border-radius: 15px!important;
  -webkit-border-radius: 15px!important;
  -moz-border-radius: 15px!important;
  -ms-border-radius: 15px!important;
  -o-border-radius: 15px!important;
}

#chatBox{
  max-height: 0;
  overflow: hidden;
  border-radius: 5px;
  transition: max-height 0.5s ease;
}


#chatBox.active-chatbox {
  max-height: 100%;
  width: 100%;
}
  
.bx-shadow-sky{
  -webkit-box-shadow: 0px 0px 15px -4px #0099e1; 
box-shadow: 0px 0px 15px -4px #0099e1;
}