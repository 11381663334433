@tailwind base;
@tailwind components;
@tailwind utilities;

.carousel-container {
  display: block;
  margin: 0 auto;
  margin-top: 20px;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 180px;
}
@media screen and (max-width: 981px) {
  .carousel-container{
  height: 210px;

  }
}
@media screen and (max-width: 504px) {
  .carousel-container{
  height: 245px;

  }
}
@media screen and (max-width: 324px) {
  .carousel-container{
  height: 275px;

  }
}
.carousel-content {
  position: absolute;
  animation: defilement 18s infinite linear;
  -webkit-animation: defilement 18s infinite linear;
  /*alignelesélémentsverticalementaucentre*/overflow: auto;

}

.carousel-item {
    /* Changed from min-width to flex */
  flex: 0 0 auto; /* Cela empêche les éléments de se réduire ou de s'agrandir */
  width: 150px; /* Largeur fixe */
  height: 150px; /* Hauteur fixe */
  padding: 10px; /* Espace autour de chaque image */
  margin-right: 20px; 
}
.carousel-item img {
  height: 40px;
  width: auto;
  /* max-width: 100%;
  max-height: 100%; */
  object-fit: contain; /* Garde les proportions de l'image */
}
.infinite-carroussel-img{

}
@keyframes defilement {
  0% { transform: translateX(0%); -webkit-transform: translateX(0%); -moz-transform: translateX(0%); -ms-transform: translateX(0%); -o-transform: translateX(0%); }
  100% { transform: translateX(-50%); -webkit-transform: translateX(-50%); -moz-transform: translateX(-50%); -ms-transform: translateX(-50%); -o-transform: translateX(-50%); }
}
