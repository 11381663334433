nav {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 100; /* Ajustez cette valeur pour qu'elle soit supérieure à celle des autres éléments de la page */
  }
  
  .active-link{
    
  }

  /* Par défaut, l'élément est visible */
.language-switcher {
  display: block;
}

/* Cachez l'élément quand la largeur de l'écran est supérieure à 768 pixels */
@media (min-width: 769px) {
  .language-switcher {
    display: none;
  }
}
