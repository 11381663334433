.feature-1 .wrap-icon {
    margin: 0 auto;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    position: relative;
    margin-bottom: 30px;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.feature-1 .wrap-icon.icon-1 {
    background: linear-gradient(-45deg, #3b87bd, #2d71a2);
}

.feature-1 .wrap-icon i {
    font-size: 40px;
    line-height: 0;
    color: #fff;
}

.feature-1 h3 {
    font-size: 20px;
}

.feature-1 p {
    color: #b1b1b1;
}

.section-heading {
    font-weight: 700;
    background: linear-gradient(-45deg, #3db3c5, #274685);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}